/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import UrlCollect from "../../common/url-collect";
import "./project.scss";
import * as projectActions from "../../redux/store/project/project.store";
import * as appActions from "../../core/app.store";
import PublishUnit from "../../components/publish-unit/publish-unit";

function PriorityDetail(props) {
    const { showLoading } = props;
    const { priorityId } = props.match.params;
    const [priorityModel, setPriorityModel] = useState();
    const [listPriority, setListPriority] = useState([]);
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetPriorityDetail(priorityId),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetPriorityDetail = (id) => {
        return new Promise((resolve, reject) => {
            projectActions.GetDetailProject(id).then(
                (res) => {
                    setPriorityModel(
                        res.content
                    );
                    onGetListPriority({ pageIndex: 1, pageSize: 5});
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListPriority = (data) => {
        return new Promise((resolve, reject) => {
            projectActions.GetListProject(data).then(
                (res) => {
                    setListPriority(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const showPageHeader = () => {
        return (
            <div>
                <div className="row link-history">
                    <div className="col-12">
                        <a href="/">Trang chủ</a>
                        &nbsp;&raquo;&nbsp;
                        <span>Dự án ưu tiên</span>
                    </div>
                </div>
                
            </div>
        )
    }

    return (
        <div className="container">
            {
                showPageHeader()
            }
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div className="row">
                        <div className="col-12">
                            {
                                priorityModel && (
                                    <div className="priority-header">
                                        {priorityModel.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            {
                                priorityModel && priorityModel.statement && (
                                    <div className="priority-desc new-content" dangerouslySetInnerHTML={{ __html: priorityModel.description }}>
                                    </div>
                                )
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        {
                            priorityModel && priorityModel.statement && (
                                <div className="col-12">
                                    <div className="project-more-info">
                                        <span>Lĩnh vực:</span> {priorityModel.projectFieldName}
                                    </div>
                                    <div className="project-more-info">
                                        <span>Loại dự án:</span> {priorityModel.projectTypeName}
                                    </div>
                                    <div className="project-more-info">
                                        <span>Trạng thái dự án:</span> {priorityModel.projectStatusName}
                                    </div>
                                </div>
                                )
                        }

                    </div><br/>
                    <div className="row">
                        <div className="col-12">
                            <div className="header-widget">
                                <div className="title">
                                    Hồ sơ liên quan
                                </div>
                            </div>
                            {
                                listPriority && listPriority.length > 0 && (
                                    listPriority.map((priority, index) => <div key={index}>
                                        <div className="priority-title">
                                            <a href={UrlCollect.DetailProject + "/" + priority.id}>{priority.name}</a>
                                        </div>
                                    </div>)
                                )
                            }
                        </div>
                    </div>
                    <br />
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <div className="row">
                        <div className="col-12">
                            <div className="header-widget">
                                <div className="title">
                                    Thông tin thêm
                                </div>
                            </div>
                            <div className="content-result priority-more-info">
                                <span>Email liên hệ</span>:
                                banbientap@mpi.gov.vn
                                <hr />
                                <span>Cơ qua ban hành</span>:&nbsp;
                                {
                                    priorityModel && (
                                        <a>{priorityModel.governmentName}</a>
                                    )
                                }
                                <hr />
                                <span>Địa phương</span>:&nbsp;
                                {
                                    priorityModel && (
                                        <a>{priorityModel.provinceName}</a>
                                    )
                                }
                                <hr />
                                <span>Chủ đầu tư</span>:&nbsp;
                                {
                                    priorityModel && (
                                        <a>{priorityModel.investor}</a>
                                    )
                                }
                                <hr />
                                <span>Diện tích</span>:&nbsp;
                                {
                                    priorityModel && (
                                        <a>{priorityModel.acreages}</a>
                                    )
                                }

                            </div>
                    </div>
                    </div>
                    
                    <PublishUnit/>
                </div>
            </div>
        </div>
      );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(PriorityDetail));
