/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./project.scss";
import Leftmenu from "../planning/leftmenu/leftmenu";
import * as dropdownActions from "../../redux/store/dropdownlist/dropdownlist.store";
import * as appActions from "../../core/app.store";
import * as projectActions from "../../redux/store/project/project.store";
import Pagination from "../../components/pagination/pagination.view";
import config from "../../api/api-config"

function Project(props) {
    const { showLoading } = props;

    const [pageCount, setPageCount] = useState();
    const [listProject, setListProject] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const pageSize = 10;
    const [listGovernment, setListGovernment] = useState([]);
    const [listProvince, setListProvince] = useState([]);
    const [listProjectField, setListProjectField] = useState([]);
    const [listProjectStatus, setListProjectStatus] = useState([]);
    const [listProjectType, setListProjectType] = useState([]);
    const [keyword, setKeyword] = useState();
    const [dataSearch, setDataSearch] = useState({ pageIndex: 1, pageSize: pageSize});

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListProject(dataSearch),
            onGetListGovernment(),
            onGetListProvince(),
            onGetListProjectField(),
            onGetListProjectType(),
            onGetListProjectStatus(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListProject = (data) => {
        return new Promise((resolve, reject) => {
            projectActions.GetListProject(data).then(
                (res) => {
                    setListProject(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setTotalItemCount(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCount(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListGovernment = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListGovernment().then(
                (res) => {
                    setListGovernment(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListProvince = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListProvince().then(
                (res) => {
                    setListProvince(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListProjectField = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListProjectField().then(
                (res) => {
                    setListProjectField(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListProjectType = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListProjectType().then(
                (res) => {
                    setListProjectType(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListProjectStatus = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListProjectStatus().then(
                (res) => {
                    setListProjectStatus(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onPagingClick = (data, isClearSearch = false) => {
        dataSearch.pageIndex = data.pageIndex;
        onGetListProject(dataSearch);
    };
    const onChangeGovernment = (newValue) => {
        dataSearch.governmentId = (newValue && newValue.id) || null;
        onGetListProject(dataSearch);
    }

    const onChangeProvince = (newValue) => {
        dataSearch.provinceId = (newValue && newValue.id) || null;
        onGetListProject(dataSearch);
    }

    const onChangeProjectField = (newValue) => {
        dataSearch.projectFieldId = (newValue && newValue.id) || null;
        onGetListProject(dataSearch);
    }
    const onChangeProjectType = (newValue) => {
        dataSearch.projectTypeId = (newValue && newValue.id) || null;
        onGetListProject(dataSearch);
    }
    const onChangeProjectStatus = (newValue) => {
        dataSearch.projectStatusId = (newValue && newValue.id) || null;
        onGetListProject(dataSearch);
    }

    const onSearchClick = () => {
        onGetListProject(dataSearch);
    }
    const onKeywordKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchClick();
        }
    }

    const showSearchBox = () => {
        return (
            <div className="row">
                <div className="col-md-6 col-12"></div>
                <div className="col-md-6 col-12 text-right">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Nhập tên dự án"
                            onChange={(e) => { dataSearch.keyword=e.target.value }} onKeyDown={(e) => { onKeywordKeyDown(e) }}
                            aria-label="Input group example" aria-describedby="btnGroupAddon" />
                        <div className="input-group-prepend">
                            <div className="input-group-text priority-search-button" id="btnGroupAddon" onClick={() => onSearchClick()}>Tìm kiếm</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const viewProject = (project) => {
        window.location = '/xem-du-an/'+project.planningId+'/'+project.id+'/'+project.layerId;
    }

    const getDownloadLink = (project) => {
        let fileDownload = '/files/csdl.zip';
        if(Array.isArray(project.projectFiles) && project.projectFiles.length >0){
            fileDownload = config.api + project.projectFiles[0].files.filePreview;
        }
        return fileDownload;
    }

    const showListProject = (project, index) => {
        return (
            <div className="row" key={index}>
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="priority-search-result">
                            <div className="project-title">
                                <a href={`/xem-du-an/${project.planningId}/${project.id}/${project.layerId}`}>{project.name}</a>
                            </div>
                            {project.description && (
                                <div className="project-desc">
                                    <p className="new-desc" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                                </div>
                            )}
                            <div className="project-more-info">
                                <span>Lĩnh vực:</span> {project.projectFieldName}
                            </div>
                            <div className="project-more-info">
                                <span>Loại dự án:</span> {project.projectTypeName}
                            </div>
                            <div className="project-more-info">
                                <span>Trạng thái dự án:</span> {project.projectStatusName}
                            </div>
                            <div className="project-action-list">
                                <a target="_blank" href={getDownloadLink(project)}>
                                <button type="button" className="btn btn-sm btn-success">
                                    <svg width="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download"
                                         className="svg-inline--fa fa-download fa-w-16" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                              d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                                    </svg>
                                    &nbsp;
                                    Tải CSDL
                                </button>
                                </a>
                                {project.planningId && project.planningId >0 && (
                                    <button onClick={() => viewProject(project)} type="button" className="btn  btn-sm btn-primary">
                                        <svg width="20" aria-hidden="true" focusable="false" data-prefix="far" data-icon="map"
                                             className="svg-inline--fa fa-map fa-w-18" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                            <path fill="currentColor"
                                                  d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM224 90.42l128 45.19v285.97l-128-45.19V90.42zM48 418.05V129.07l128-44.53v286.2l-.64.23L48 418.05zm480-35.13l-128 44.53V141.26l.64-.24L528 93.95v288.97z"></path>
                                        </svg>
                                        &nbsp;
                                        Xem bản đồ
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    const showPaging = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                    {listProject && listProject.length > 0 && (
                        <div className="list-footer">
                            <div
                                className={`text-center text-result ${pageCount && pageCount > 1 ? "float-left" : ""
                                    }`}
                            >
                                <span>Có {totalItemCount} kết quả</span>
                            </div>
                            {pageCount && pageCount > 1 && (
                                <div className="float-right">
                                    <Pagination
                                        totalItems={totalItemCount}
                                        currentIndex={currentPageIndex}
                                        pageSize={pageSize}
                                        onClick={(pageIndex) => {
                                            onPagingClick({
                                                pageIndex: pageIndex
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
    <div className="container">
        <div className="row link-history">
            <div className="col-12">
                <a href="/">Trang chủ</a>
                &nbsp;&raquo;&nbsp;
                <span>Dự án ưu tiên</span>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <div className="priority-header">
                    Dự Án Ưu Tiên
                </div>
            </div>
        </div>
        <div className="row">
                <Leftmenu
                    listGovernment={listGovernment}
                    onChangeGovernment={onChangeGovernment}
                    listProvince={listProvince}
                    onChangeProvince={onChangeProvince}
                    listProjectField={listProjectField}
                    onChangeProjectField={onChangeProjectField}
                    listProjectType={listProjectType}
                    onChangeProjectType={onChangeProjectType}
                    listProjectStatus={listProjectStatus}
                    onChangeProjectStatus={onChangeProjectStatus}
                />
            <div className="col-md-9">
                    {showSearchBox()}
                    {
                        listProject && listProject.length > 0 && (
                            listProject.map((project, index) => showListProject(project, index))
                        )
                    }
                    {showPaging()}
            </div>
        </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
          showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Project));
