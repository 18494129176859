import React, { useState } from "react";
import "./area-leftmenu.scss";
import { connect } from "react-redux";

function AreaLeftmenu(props:any) {
  return (
    <div className="left-menu-custom-container h-100 position-relative">
      <div className="left-area-title">
        Quy hoạch vùng
      </div>
      <div className="left-area-item item-color-1">
          <a href="/ban-do-quy-hoach/9">Trung du và Miền núi phía bắc</a>
      </div>
      <div className="left-area-item item-color-2">
        <a href="/ban-do-quy-hoach/10">Đồng bằng sông Hồng</a>
      </div>
      <div className="left-area-item item-color-3">
          <a href="/ban-do-quy-hoach/11">Bắc Trung bộ và Duyên hải miền Trung</a>
      </div>
      <div className="left-area-item item-color-4">
          <a href="/ban-do-quy-hoach/12">Tây Nguyên</a>
      </div>
      <div className="left-area-item item-color-5">
          <a href="/ban-do-quy-hoach/13">Đông Nam Bộ</a>
      </div>
      <div className="left-area-item item-color-6">
          <a href="/ban-do-quy-hoach/8">Đồng bằng sông Cửu Long</a>
      </div>
        <a title="Cơ sở dữ liệu liên quan" href="/quy-hoach/5">
            <div className="related-button">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
                     className="svg-inline--fa fa-arrow-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                </svg>
                &nbsp;
                <span>Cơ sở dữ liệu liên quan </span>
            </div>
        </a>
    </div>
  );
}

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps)(AreaLeftmenu);
