import React, { useState, useEffect } from "react";
import "./trial.scss";

function Trial() {
  return (
      <div className="trial-box-container">
          <div className="trial-box">
              <marquee>
              <span>* Phiên bản thử nghiệm</span>
              </marquee>
          </div>
      </div>
  );
}
export default (Trial);
