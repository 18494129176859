const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
      api: "https://quyhoachquocgia-api.cgis.asia/",
      DomainUserSite:"http://localhost:3000",
      DomainAdminSite:"http://localhost:3000",
      DomainName:"",
      basemapVnUrl: "https://gisserver.bandovn.vn:6443/arcgis/rest/services/VietNam/MapServer/tile/{z}/{y}/{x}?token=xCCr-n-rq98EzHoGzZheSJi60ueGdYK8iO6h1R5cgysAffy3Kb-K0VfIRlwR9MbvBDVBehQ7o1hv9vp4RiEx3A.."
  },
    // production: {
    //   api: "https://qhqg-api.cgis.asia/",
    //   DomainUserSite:"https://vplan.mpi.gov.vn",
    //   DomainAdminSite:"https://adminvplan.mpi.gov.vn",
    //   DomainName:"vplan.mpi.gov.vn",
    //   basemapVnUrl: "https://gisserver.bandovn.vn:6443/arcgis/rest/services/VietNam/MapServer/tile/{z}/{y}/{x}?token=zyyV5HC4S_5EKH4wnWYrffL4fMn8yeK67dHNX8bydwuymVPgfW6OkfGvWwE8Lwu4DSY4n2j436QRtGM908pZ2g.."
    // },

    production: {
         api: "https://qhqg-api.cgis.asia/",
         DomainUserSite:"https://qhqg.cgis.asia",
         DomainAdminSite:"https://admin.qhqg.cgis.asia",
         DomainName:"qhqg.cgis.asia",
        basemapVnUrl: "https://gisserver.bandovn.vn:6443/arcgis/rest/services/VietNam/MapServer/tile/{z}/{y}/{x}?token=xCCr-n-rq98EzHoGzZheSJi60ueGdYK8iO6h1R5cgysAffy3Kb-K0VfIRlwR9MbvBDVBehQ7o1hv9vp4RiEx3A.."
    },
    
};

module.exports = apiEnvironment[env];