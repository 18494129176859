import React from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Vilanguage from "../../languages/vi";

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
  render() {
    return (
        <div className="footer-container">
          <div className="container">
            <div className="row">
              <div className="col-12">

              </div>
            </div>
            <div className="row">
              <div className="col-md-1 col-3">
                <div className="footer-logo">
                  <img
                      className="logo"
                      src={require("../../assets/image/logo.png")}
                      alt="Logo"
                  />
                </div>
              </div>
              <div id="footer-title-box" className="col-md-5 col-9">
                <div id="footer-title-name" className="footer-title">
                  {LanguageDisplay.footer_titleName}
                </div>
                <div className="footer-address">
                  <span>{LanguageDisplay.footer_address}</span>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="row">
                  <div className="col-md-6 col-6">
                <span>
                  Tel: {LanguageDisplay.footer_hotline}
                </span>
                  </div>
                  <div className="col-md-6 col-6">
                <span>
                  {Vilanguage.footer_fax && (
                      <span> Fax: {Vilanguage.footer_fax}</span>
                  )}
                </span>
                  </div>
                  <div className="col-md-6 col-12">
                    <span>E-mail: {LanguageDisplay.footer_email}</span>
                  </div>
                  <div className="col-md-6 col-12 footer_website">
                    <span>Website: <a target="_blank" href={LanguageDisplay.footer_website_to}>{LanguageDisplay.footer_website}</a> </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
