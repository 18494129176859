/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    TextField,
} from "@material-ui/core";

function Category(props) {
    const {
        listCategory,
        onChangeCategory
    } = props;

    const handleOnSelect = (item) => {
        onChangeCategory(item);
    }

    return (
        <div className="collapse-item">
            {
                (listCategory && listCategory.length > 0) && (
                    <Autocomplete
                        options={listCategory}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) => {
                            handleOnSelect(newValue);
                        }}
                        
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="planningCategoryId"
                                variant="outlined"
                                size="small"
                            />
                        )}
                    />
                    )
            }
            </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Category));
