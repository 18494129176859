import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../core/app.store";
import Pagination from "../../components/pagination/pagination.view";
import {APIUrlDefault} from "../../utils/configuration";
import "@geedmo/yamm/src/yamm.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./list.scss";
import * as videosActions from "../../redux/store/videos/videos.store";
import moment from "moment";
import LightboxVideo from "./Lightbox";
const firstLoad = true;

function ListVideosView(props) {
  const { showLoading } = props;

  const [currentPageIndexDoing, setCurrentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 20;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const [pageCountDoing, setPageCountDoing] = useState();
  const [listHotVideos, setHotVideosModel] = useState([]);
  const [listVideos, setListVideos] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [detail, setDetail] = useState(false);
  useEffect(() => {
    onGetData();
  }, [firstLoad]);
  const onClickItem = (item) => {
    setDetail(item);
    setLightboxOpen(true)
  }
  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListVideos({
          pageIndex: currentPageIndexDoing,
          pageSize: currentPageSizeDoing,
      }),
    ])
        .then((res) => {
          showLoading(false);
        })
        .catch((err) => {
          showLoading(false);
        });
  };

    const onGetListVideos = (data) => {
        return new Promise((resolve, reject) => {
            videosActions.GetListVideos(data).then(
                (res) => {
                    setListVideos(
                        res &&
                        res.content &&
                        res.content.items
                        && res.content.items.length > 0 ? res.content.items: []
                    );
                    setTotalItemCountDoing(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCountDoing(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }


  const onPagingClick = (data, isClearSearch = false) => {
        onGetListVideos({
                pageIndex: data.pageIndex,
                pageSize: currentPageSizeDoing,
            }
        );
  };

  return (
      <div className="container">
          <div className="row">
              <div className="col-md-12 listvideos-title">
                  VIDEO
              </div>
          </div>
          {
              lightboxOpen && (
                <LightboxVideo detail={detail} open={lightboxOpen} onClose={()=>setLightboxOpen(false)} />
              )
          }
          <div className="list-videos common-videos">
              <div className="row">
                  {listVideos && listVideos.length >0 ? (
                      listVideos.map((item,index) => (
                          <div key={index} className="videos-item col-lg-3">
                              <div className="videos-image">
                                  <Link onClick={()=>onClickItem(item)}>
                                      <img
                                          src={item.youtubeThumbnail}
                                          onError={(e) =>
                                              (e.target.src = require("../../assets/image/no_image.png"))
                                          }
                                          alt={item.title}
                                      />
                                  </Link>
                              </div>
                              <div className="cb"></div>
                              <div className="videos-info">
                                  <Link onClick={()=>onClickItem(item)} className="videos-title">{item.title}</Link>
                                  <div className="new-date">{moment(new Date(item.created_date)).format('M')} tháng {moment(new Date(item.created_date)).format('M')}, {moment(new Date(item.created_date)).format('YYYY')} </div>
                              </div>
                          </div>
                      ))
                  ):(
                      <div className="videos-item">Chưa có dữ liệu để hiển thị!</div>
                  )}
              </div>
              <div className="row mt-4">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                      {listVideos && listVideos.length > 0 && (
                          <div className="list-footer">
                              <div
                                  className={`text-center text-result ${
                                      pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                                  }`}
                              >
                                  <span>Có {totalItemCountDoing} kết quả</span>
                              </div>
                              {pageCountDoing && pageCountDoing > 1 && (
                                  <div className="float-right">
                                      <Pagination
                                          totalItems={totalItemCountDoing}
                                          currentIndex={currentPageIndexDoing}
                                          pageSize={currentPageSizeDoing}
                                          onClick={(pageIndex) => {
                                              onPagingClick({
                                                  pageIndex: pageIndex
                                              });
                                          }}
                                      />
                                  </div>
                              )}
                          </div>
                      )}
                  </div>
              </div>
          </div>

      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          showLoading: appActions.ShowLoading,
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListVideosView);
