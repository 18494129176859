import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListHotNotification = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);

  data.id && params.append("id", data.id);
  data.title && params.append("title", data.title);
  data.categoryId && params.append("categoryId", data.categoryId);
  data.image_Url && params.append("image_Url", data.image_Url);
  data.name && params.append("name", data.name);
  data.status && params.append("status", data.status);
  data.description && params.append("description", data.description);
  data.content && params.append("content", data.content);
  data.createdDate && params.append("createdDate", data.createdDate);
  data.modifiedDate && params.append("modifiedDate", data.modifiedDate);

  return service
      .get(ApiUrl.GetListHotNotification, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const SearchNotification = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("keyword", data.keyword);
  params.append("pageSize", data.pageSize);

  data.id && params.append("id", data.id);
  data.title && params.append("title", data.title);
  data.categoryId && params.append("categoryId", data.categoryId);
  data.image_Url && params.append("image_Url", data.image_Url);
  data.name && params.append("name", data.name);
  data.status && params.append("status", data.status);
  data.description && params.append("description", data.description);
  data.content && params.append("content", data.content);
  data.createdDate && params.append("createdDate", data.createdDate);
  data.modifiedDate && params.append("modifiedDate", data.modifiedDate);

  return service
      .get(ApiUrl.SearchNotification, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetListNotification = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);

  data.id && params.append("id", data.id);
  data.title && params.append("title", data.title);
  data.categoryId && params.append("categoryId", data.categoryId);
  data.image_Url && params.append("image_Url", data.image_Url);
  data.name && params.append("name", data.name);
  data.status && params.append("status", data.status);
  data.description && params.append("description", data.description);
  data.content && params.append("content", data.content);
  data.createdDate && params.append("createdDate", data.createdDate);
  data.modifiedDate && params.append("modifiedDate", data.modifiedDate);

  return service
      .get(ApiUrl.GetListNotification, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetDetailNotification = (id) => {
  let requestUrl = ApiUrl.GetDetailNotification + '/' +id;

  return service
    .get(requestUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDetailFormTemplate = (id) => {
  const params = new URLSearchParams();
  params.append("NotificationId", id);

  return service
    .get(ApiUrl.GetDetailFormTemplate, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateFeedback = (data) => {
  return service
    .post(ApiUrl.CreateFeedback, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListExpiredForm = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  data.sortExpression && params.append("sortExpression", data.sortExpression);
  data.title && params.append("title", data.title);
  data.NotificationId &&
    params.append("NotificationId", data.NotificationId);

  return service
    .get(ApiUrl.GetListExpiredForm, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetReportFeedback = (id) => {
  const params = new URLSearchParams();
  params.append("templateId", id);

  return service
    .get(ApiUrl.GetReportFeedback, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
