import React from "react";
import { Router, Switch } from "react-router-dom";
import RouteComponent from "./route-config.jsx";
import history from "./common/history";
import UrlCollect from "./common/url-collect";
import 'suneditor/dist/css/suneditor.min.css'; 
//--- Loading
import AppLoading from "./components/loading/loading.view";

//--- Layout
import LayoutDesktopView from "./components/layout/layout.view.jsx";
import LayoutHomeView from "./components/layout/layout-home.view.jsx";
import LayoutUserDesktopView from "./components/layout/layout-user.view.jsx";
import LayoutMapPageView from "./components/layout-map-page/layout-map-page.view.jsx";

//--- Homepage
import HomeDesktop from "./modules/home/home.view.jsx";
import RecordsManagementDesktopView from "./modules/records-management/records-management.view.jsx";
import ContactDesktopView from "./modules/contact/contact.view.jsx";
import LookUpPlanningView from "./modules/look-up-planning/look-up-planning.view";
import PlanningMapView from "./modules/planning-map-view/planning-map-view.view.tsx";

//--Introductin

import IntroView from "./modules/intro/intro";

//--Project

import Planning from "./modules/planning/planning";
import PlanningDetail from "./modules/planning/detail";
import PlanningDocument from "./modules/planning/document";
import PriorityProject from "./modules/project/project";
import PriorityDetail from "./modules/project/detail";

// Document

import DocumentList from "./modules/document/document";

//-- User
import LoginDesktop from "./modules/login/login.view.jsx";
import RegisterDesktop from "./modules/register/register.view.jsx";
import ForgotPasswordDesktop from "./modules/forgot-password/forgot-password.view.jsx";
import ResetPasswordDesktop from "./modules/reset-password/reset-password.view";

//--- Xin y kien cong dong
import PleaseConsultTheCommunityDesktopView from "./modules/please-consult-the-community/please-consult-the-community.view.jsx";
import PleaseCommentView from "./modules/please-consult-the-community/please-comment/please-comment.view.jsx";
import SummaryIdeasView from "./modules/please-consult-the-community/summary-ideas/summary-ideas.view.jsx";

//-- Tin tuc

import NewsListView from "./modules/news/list.view.jsx";
import VideosListView from "./modules/videos/list.view.jsx";
import NewsDetailView from "./modules/news/detail.view.jsx";

import InformListView from "./modules/notification/list.view.jsx";
import InformDetailView from "./modules/notification/detail.view.jsx";

//--- Cong bo quy hoach
import PlanningAnnouncementDesktopView from "./modules/planning-announcement/planning-announcement.view.jsx";
import PlanningAnnouncementDetailDesktopView from "./modules/planning-announcement/planning-announcement-detail/planning-announcement-detail.view.jsx";
import GeneralPlanningView from "./modules/general-planning/general-planning.view.tsx";

import EntryMap from "./modules/entry/entry";

// User Info
import LayoutUserInfo from "./components/layout/layout-user-info.view.jsx";
import AccountInfoView from "./modules/user-info/account-info/account-info.view.jsx";
import QuyHoachView from "./modules/user-info/quy-hoach/index.view";

//--- Error pages
import ErrorPage500 from "./modules/error-page/page-500/page-500";
import changePasswordView from "./modules/user-info/change-password/change-password.view.jsx";
import accountInfoView from "./modules/user-info/account-info/account-info.view.jsx";
import projectInfoView from "./modules/user-info/project-info/project-info.view.jsx";
import PlanningProvincial from "./modules/planning-provincial/planning-provincial.view.jsx";
import PlanningReport from "./modules/planning-report/index.view.jsx";
import PlanningRegionNation from "./modules/planning-region-nation/index.view.jsx";
import ThongTinQuyHoach from "./modules/thong-tin-quy-hoach/index.view.jsx";

function App() {
  return (
    <div>
      <AppLoading />

      <Router history={history}>
        <Switch>
          {/* Desktop */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={IntroView}
            path={UrlCollect.Intro}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={DocumentList}
            path={UrlCollect.DocumentList}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={Planning}
            path={UrlCollect.Planning + "/:categoryId"}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={Planning}
            path={UrlCollect.PlanningByProvince + "/:categoryId/:provinceId"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningDetail}
            path={UrlCollect.DetailedPlanning + "/:planningId"}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningDocument}
            path={UrlCollect.PlanningDocument + "/:planningId"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PriorityProject}
            path={UrlCollect.PriorityProject}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PriorityDetail}
            path={UrlCollect.DetailProject + "/:priorityId"}
          />

          <RouteComponent
            exact
            layout={LayoutHomeView}
            component={HomeDesktop}
            path={UrlCollect.Home}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PleaseConsultTheCommunityDesktopView}
            path={UrlCollect.PleaseConsultTheCommunity}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningAnnouncementDesktopView}
            path={UrlCollect.PlanningAnnouncement}
          />
          {/* Tin tuc */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={NewsDetailView}
            path={UrlCollect.NewsDetail + "/:id"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={NewsListView}
            path={UrlCollect.NewsList}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={VideosListView}
            path={UrlCollect.VideosList}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={InformDetailView}
            path={UrlCollect.InformDetail + "/:id"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={InformListView}
            path={UrlCollect.InformList}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={RecordsManagementDesktopView}
            path={UrlCollect.RecordsManager}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ContactDesktopView}
            path={UrlCollect.Contact}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={GeneralPlanningView}
            path={UrlCollect.SyntheticPlanning}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={EntryMap}
            path={UrlCollect.AreaPlanning + ":categoryId"}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={EntryMap}
            path={UrlCollect.ProvincePlanning + ":categoryId"}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={EntryMap}
            path={UrlCollect.SpecialPlanning + ":categoryId"}
          />

          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={LookUpPlanningView}
            path={UrlCollect.PlanningMap}
          />
          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={PlanningMapView}
            path={UrlCollect.PlanningMapViewDetail + ":id"}
          />

          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={PlanningMapView}
            path={UrlCollect.ProjectMapViewDetail + ":id/:projectId/:layerId"}
          />

          <RouteComponent
            exact
            layout={LayoutMapPageView}
            component={PlanningMapView}
            path={UrlCollect.ProjectMapViewLocation + ":id/:lat/:lng"}
          />

          {/* User Desktop */}
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={LoginDesktop}
            path={UrlCollect.Login}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={RegisterDesktop}
            path={UrlCollect.Register}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={ForgotPasswordDesktop}
            path={UrlCollect.ForgotPassword}
          />
          <RouteComponent
            exact
            layout={LayoutUserDesktopView}
            component={ResetPasswordDesktop}
            path={UrlCollect.ResetPassword + "/:userId"}
          />

          {/* Xin y kien cong dong */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={SummaryIdeasView}
            path={UrlCollect.SummaryIdeas}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PleaseCommentView}
            path={UrlCollect.PleaseComment + "/:id"}
          />

          {/* Cong bo quy hoach */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningAnnouncementDetailDesktopView}
            path={UrlCollect.PlanningAnnouncement + "/:id"}
          />
          {/* user info */}
          <RouteComponent
            exact
            propsTitle="Thông tin tài khoản"
            layout={LayoutUserInfo}
            component={accountInfoView}
            path={UrlCollect.UserInfo}
          />
          {/* end user info */}
          <RouteComponent
            exact
            propsTitle="Thông tin"
            layout={LayoutUserInfo}
            component={projectInfoView}
            path={`${UrlCollect.UserInfo}/:recordsId/thong-tin`}
          />
          <RouteComponent
            exact
            layout={LayoutUserInfo}
            component={QuyHoachView}
            path={`${UrlCollect.UserInfo}/:recordsId/:recordsTypeId`}
          />
          <RouteComponent
            exact
            layout={LayoutUserInfo}
            propsTitle="Đổi mật khẩu"
            component={changePasswordView}
            path={UrlCollect.ChangePassword}
          />
          {/* error pages */}
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningProvincial}
            path={UrlCollect.PlanningProvince}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningReport}
            path={UrlCollect.PlanningReport}
          />
           <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={PlanningRegionNation}
            path={UrlCollect.PlanningRegionNation}
          />
           <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ThongTinQuyHoach}
            path={`${UrlCollect.ThongTinQuyHoach}/:planningId`}
          />
          <RouteComponent
            exact
            layout={LayoutDesktopView}
            component={ErrorPage500}
            path={UrlCollect.Page500 + ":id"}
          />
          {/* lap */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
