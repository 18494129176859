import React, { useEffect, useState, cloneElement } from "react";
import TopView from "../top/top.view.jsx";
import HeaderView from "../header/header.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";

import "./layout.scss";
import { Box, Grid, Typography } from "@material-ui/core";
import LeftSideBarUserInfo from "./../left-sidebar/left-sidebar.view";
import {
  GetRecordsType,
  GetAllLogHistory,
} from "../../redux/store/user-info/user-info.store.js";
import PaginationView from "../pagination/pagination.view.jsx";
import moment from "moment";

const LayoutUserInfo = ({ match, children, propsTitle = "" }) => {
  const { recordsTypeId, recordsId } = match.params;
  const [recordsHistory, setRecordsHistory] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [title, setTitle] = useState("");
  const [recordData, setRecordData] = useState({});
  const [isFinalStep, setIsFinalStep] = useState(false);
  const pageSize = 10;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);

  const [pageCountDoing, setPageCountDoing] = useState();

  useEffect(() => {
    async function getRecordsHistory() {
      getDataLog({
        pageIndex: 1,
        pageSize: pageSize,
        rowId: recordsId,
      });
    }
    getRecordsHistory();
  }, [recordsId]);
  useEffect(() => {
    async function getRecordsType() {
      await GetRecordsType({
        recordsTypeId: recordsTypeId,
      }).then((res) => {
        setTitle(res?.content[0]?.name);
        setRecordData(res?.content[0]?.recordsFileKinds);
        setIsFinalStep(!!res?.content[0]?.isFinalStep);
      });
    }
    getRecordsType();
  }, [recordsTypeId]);

  const getDataLog = async (data) => {
    await GetAllLogHistory(data).then((res) => {
      if (res && res.content) {
        setRecordsHistory(
          res &&
            res.content &&
            res.content.items &&
            res.content.items.length > 0
            ? res.content.items
            : []
        );
        setTotalItemCountDoing(
          res && res.content && res.content.totalItemCount
            ? res.content.totalItemCount
            : 0
        );
        setPageCountDoing(
          res && res.content && res.content.pageCount
            ? res.content.pageCount
            : 0
        );
      }
    });
  };
  const onPagingClick = (data, isClearSearch = false) => {
    GetAllLogHistory({
      pageIndex: data.pageIndex,
      pageSize: pageSize,
      rowId: recordsId,
    });
  };
  return (
    <div className="layout-container layout-user-info">
      <TopView />
      <HeaderView match={match} />
      <div>
        <Grid container spacing={3} className="container-info">
          <Grid item xs={3}>
            <LeftSideBarUserInfo />
          </Grid>
          <Grid item xs={9}>
            <div className="d-flex justify-content-between">
              <Typography className="title">{propsTitle || title}</Typography>
              {propsTitle !== "Thông tin tài khoản" ? (
                <div
                  className="history-system"
                  onClick={() => setShowHistory(true)}
                >
                  {" "}
                  <IconHistory /> Nhật ký hệ thống
                </div>
              ) : (
                ""
              )}
            </div>
            <Box p={[3, 4, 6]} className="content">
              {cloneElement(children, {
                recordData,
                isFinalStep,
              })}
            </Box>
            {/* {showHistory && ( */}
            <div
              className={`container-history-system ${
                showHistory ? "active-history" : "deactive-history"
              }`}
            >
              <div className="d-flex header justify-content-between">
                <div className="title-history">Nhật ký hệ thống</div>
                <div className="icon" onClick={() => setShowHistory(false)}>
                  <IconClose />
                </div>
              </div>
              <div className="content-history">
                {recordsHistory.map((item) => (
                  <div className="item-history">
                    <div className="d-flex">
                      <div className="icon-dot">
                        <IconDot />
                      </div>
                      <div className="item-content">
                        <div className="date-time">{moment(new Date(item.createdDate)).format('hh:mm')} ngày {moment(new Date(item.createdDate)).format('DD/MM/YYYY')}</div>
                        <div className="description">
                         {item.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="pagination-video">
                  <PaginationView
                    totalItems={totalItemCountDoing}
                    currentIndex={1}
                    pageSize={pageSize}
                    onClick={(pageIndex) => {
                      onPagingClick({
                        pageIndex: pageIndex,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            {/* )} */}
          </Grid>
        </Grid>
      </div>
      {/* <FooterView /> */}
      <LoadingWithApiQueue />
    </div>
  );
};

export default LayoutUserInfo;

const IconHistory = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4994 9.91406C16.416 9.91406 16.2494 9.9974 16.166 10.0807L15.3327 10.9141L17.0827 12.6641L17.916 11.8307C18.0827 11.6641 18.0827 11.3307 17.916 11.1641L16.8327 10.0807C16.7494 9.9974 16.666 9.91406 16.4994 9.91406ZM14.916 11.4141L9.83268 16.4141V18.1641H11.5827L16.666 13.0807L14.916 11.4141ZM9.41602 4.83073V9.16406L12.7494 11.1641L11.916 11.9974L8.16602 9.83073V4.83073H9.41602ZM8.16602 17.2474C3.91602 16.8307 0.666016 13.2474 0.666016 8.9974C0.666016 4.41406 4.41602 0.664062 8.99935 0.664062C13.416 0.664062 16.9994 4.08073 17.3327 8.41406C17.0827 8.33073 16.8327 8.2474 16.4994 8.2474C16.166 8.2474 15.916 8.33073 15.666 8.41406C15.3327 4.9974 12.4994 2.33073 8.99935 2.33073C5.33268 2.33073 2.33268 5.33073 2.33268 8.9974C2.33268 12.4141 4.91602 15.2474 8.24935 15.5807L8.16602 15.7474V17.2474Z"
        fill="#5F6368"
      />
    </svg>
  );
};
const IconClose = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const IconDot = () => {
  return (
    <svg
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill="#333333" />
    </svg>
  );
};
