import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { TextField, IconButton  } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import * as planningAction from "./../../redux/store/planning/planning.store";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "./../../languages/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import { useForm, Controller } from "react-hook-form";
import "./style.scss";
import PaginationView from "../../components/pagination/pagination.view";
import MuiSelect from "../../components/select";
import { QUYHOACH } from "../../common/constant";

const PlanningReport = () => {
  const [yearData, setYearData] = useState([]);
  const [planningStatus, setPlanningStatus] = useState([]);
  const [records, setRecords] = useState([]);
  const [currentPageIndexDoing] = useState(1);
  const currentPageSizeDoing = 50;
  const [totalItemCountDoing, setTotalItemCountDoing] = useState(0);
  const [planningKindOptions] = useState([
    {
      label: "Tất cả",
      value: null,
    },
    {
      label: "Quy hoạch cấp tỉnh",
      value: QUYHOACH.TINH,
    },
    {
      label: "Quy hoạch cấp quốc gia, vùng",
      value: QUYHOACH.VUNG,
    },
  ]);

  const { handleSubmit, control } = useForm();
  const onSubmit = data => {
    getData(1, currentPageSizeDoing, data);
  };

  const [pageCountDoing, setPageCountDoing] = useState();
  const onPagingClick = (data, isClearSearch = false) => {
    getData(data.pageIndex, currentPageSizeDoing);
  };
  useEffect(() => {
    const yearFake = new Date().getFullYear();
    const tmp = [];
    for (let index = yearFake - 5; index < yearFake + 10; index++) {
      tmp.push({
        label: index,
        value: index,
      });
    }

    setYearData([
      {
        label: "Tất cả",
        value: null,
      },
      ...tmp
    ]);
  }, []);

  useEffect(() => {
    planningAction
      .GetLookupRecordsType()
      .then((res) => {
        if (res && res.content && res.content) {
          const planningStatus = res.content?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          setPlanningStatus([
            {
              label: "Tất cả",
              value: null,
            },
            ...planningStatus,
          ]);
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  }, []);
  useEffect(() => {
    getData(1, currentPageSizeDoing)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageIndex, pageSize, params = {}) => {

    const { name, planningStatus, year, planningKind } = params;

    let param = `?Year=${year}`;

    if (planningStatus) {
      param = param + `&RecordsTypeId=${planningStatus}`;
    }
    if(pageIndex) {
      param = param + `&pageIndex=${pageIndex}`
    }
    if(pageSize) {
      param = param + `&pageSize=${pageSize}`;
    }
    if (name) {
      param = param + `&Name=${name}`;
    }
    if (planningKind) {
      param = param + `&PlanningKindId=${planningKind}`;
    }

    planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content && res.content) {
          setRecords(res.content.items);
          setTotalItemCountDoing(res.content.totalItemCount);
          setPageCountDoing(
            res && res.content && res.content.pageCount
                ? res.content.pageCount
                : 0
        );
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  }
  return (
    <div className="container planning-provincial-wrapper">
      <div className="d-flex header-planning">
        <div className="title">Báo cáo tình hình lập quy hoạch</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className="d-flex form">
        <Controller
          as={TextField}
          name="name"
          control={control}
          label="Tên quy hoạch"
          defaultValue=""
          variant="outlined"/>
        <Controller
          as={MuiSelect}
          control={control}
          name="planningKind"
          label="Loại quy hoạch"
          defaultValue=""
          options={planningKindOptions}/>
        <Controller
          as={MuiSelect}
          control={control}
          name="year"
          label="Năm"
          defaultValue={null}
          options={yearData}/>
        <Controller
          as={MuiSelect}
          control={control}
          name="planningStatus"
          label="Trạng thái"
          defaultValue={null}
          options={planningStatus}/>
        
        <IconButton
          type="submit"
          variant="contained"
          color="primary"
          className="button-search">
          <SearchIcon/>
        </IconButton>
      </form>
      <div className="content-planning">
        {records.length > 0 ? (
          <Table striped hover style={{ border: "1px solid #EAEAEA" }}>
            <thead>
              <tr>
                <th>STT</th>
                <th>Cấp quy hoạch</th>
                <th>Tên quy hoạch</th>
                <th>Cơ quan lập</th>
                <th>Tình trạng lập quy hoạch</th>
                <th>Kỳ lập quy hoạch</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.planningKindName}</td>
                  <td>
                    <a href={`/thong-tin-quy-hoach/${item.id}`}>{item.name}</a>
                  </td>
                  <td>{item.planningAgency}</td>
                  <td>{item.recordsTypeName}</td>
                  <td> {`${item.fromYear} - ${item.toYear}`}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: "center" }}>Chưa có dự án</div>
        )}
      </div>
      <div className="row mt-4">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
          {records && records.length > 0 && (
            <div className="list-footer">
              <div
                className={`text-center text-result ${
                  pageCountDoing && pageCountDoing > 1 ? "float-left" : ""
                }`}
              >
                <span>Có {totalItemCountDoing} kết quả</span>
              </div>
              {pageCountDoing && pageCountDoing > 1 && (
                <div className="float-right">
                  <PaginationView
                    totalItems={totalItemCountDoing}
                    currentIndex={currentPageIndexDoing}
                    pageSize={currentPageSizeDoing}
                    onClick={(pageIndex) => {
                        onPagingClick({
                            pageIndex: pageIndex
                        });
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanningReport;
