/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    TextField,
} from "@material-ui/core";

function Search(props) {
    const[listSearch,setListSearch] = useState([])
    const[itemList,setItemList] = useState([])
    const {mapData,onChangeSearch} = props;

    useEffect(() => {
        GetFlatLayerList(mapData);
    }, [mapData]);

    const getFlatLayers = (data,layerList) => {
        if(Array.isArray(data) && data.length >0){
            data.map((item) => {
                layerList[item.id] = item;
                if(Array.isArray(item.children) && item.children.length >0){
                    layerList = getFlatLayers(item.children,layerList);
                }
            })
        }
        return layerList;
    }

    const GetFlatLayerList = (map) => {
        if(map && Array.isArray(map.layer_categories) && map.layer_categories.length >0){
            let data = map.layer_categories;
            let layerList = [];
            let itemList = [];
            layerList = getFlatLayers(data,layerList);
            if(layerList && Array.isArray(layerList) && layerList.length > 0){
                layerList.map((layer) => {
                    let settingList = layer.layer_settings;
                    if(settingList && Array.isArray(settingList) && settingList.length >0){
                        settingList.map((setting) => {
                            itemList.push(setting);
                        })
                    }
                })
            }
            setItemList(itemList);
            if(itemList && Array.isArray(itemList) && itemList.length >0){
                let listSearch = [];
                itemList.map((item) => (
                    listSearch.push({'id':item.id,'name':item.name})
                ))
                setListSearch(listSearch);
            }
        }
    }

    return (
        <div className="collapse-item">
            {
                (listSearch && listSearch.length > 0) && (
                    <Autocomplete
                        options={listSearch}
                        getOptionLabel={(option) =>
                            typeof option === "string" ? option : option.name
                        }
                        onChange={(event, newValue) => {
                            onChangeSearch(newValue,itemList);
                        }}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="planningSearchId"
                                variant="outlined"
                                size="small"
                                placeholder="Nhập tên quy hoạch, dự án cần tìm"
                            />
                        )}
                    />
                )
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(Search));
