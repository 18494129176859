import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetPlanningSumaryReport = () => {
    return service.get(ApiUrl.GetPlanningSumaryReport).then((res) => {return res}).catch((err) => { throw err });
}
export const GetPlanningKindSumaryReport = () => {
    return service.get(ApiUrl.GetPlanningKindSumaryReport).then((res) => {return res}).catch((err) => { throw err });
}