import React from "react";
import { Select,
  InputLabel,
  FormControl,
  MenuItem } from "@material-ui/core";

const MuiSelect = (props) => {
  const { label, name, options, className, ...rest } = props;

  return (
    <FormControl className={`mui-select ${className || ''}`}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select labelId={name} id={name} variant="outlined" label={label} name={name} {...rest}>
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MuiSelect;