import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import * as newsActions from "../../../redux/store/news/news.store";
import {
  APIUrlDefault,
} from "../../../utils/configuration";
import dateformat from "dateformat";
import "./news.scss";

function NewsHome(props) {
  const { showLoading } = props;
  const [listNews, setListNews] = useState([]);
  const [item, setHotNews] = useState({});
  const pageSize = 5;

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetListNews({
        pageIndex: 1,
        pageSize: pageSize
      }),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetListNews = (data) => {
    return new Promise((resolve, reject) => {
      newsActions.GetListHotNews(data).then(
        (res) => {
          console.log(res);
          if(res && res.content && res.content.items && Array.isArray(res.content.items) && res.content.items.length >0){
              setListNews(res.content.items);
              setHotNews(res.content.items[0]);
          }
          resolve(res);
        },
          (err) => {
            reject(err);
          }
       );
    });
  }

  const renderNewsBox = (item,index) => {
      return (
          <div key={index} className="news-item">
              <div className="news-image">
                  <a href={"/tin-tuc-chi-tiet/" + item.id}>
                      <img
                          src={APIUrlDefault + item.image_Url}
                          onError={(e) =>
                              (e.target.src = require("../../../assets/image/no_image.png"))
                          }
                          alt={item.title}
                      />
                  </a>
              </div>
              <div className="news-info">
                  <div className="news-title">
                      <a href={"/tin-tuc-chi-tiet/" + item.id}>{item.title}</a>
                  </div>
                  <div className="news-date">
                      <img src={require("../../../assets/images/calendar.png")}/>
                      <span>{dateformat(item.created_date, "dd/mm/yyyy")}</span>
                  </div>
              </div>
              <div className="cb"></div>
          </div>
      )
  }

  const renderHotNewsBox = (item) => {
      const renderHotNews = (item) => {
          return (
              <div id="home-hot-news" className="home-hot-news">
                  <div className="news-image">
                      <a href={"/tin-tuc-chi-tiet/" + item.id}>
                          <img
                              src={APIUrlDefault + item.image_Url}
                              onError={(e) =>
                                  (e.target.src = require("../../../assets/image/no_image.png"))
                              }
                              alt={item.title}
                          />
                      </a>
                  </div>
                  <div className="news-title">
                      <a href={"/tin-tuc-chi-tiet/" + item.id} >{item.title}</a>
                  </div>

                  <div className="news-date">
                      <img src={require("../../../assets/images/calendar.png")}/>
                      <span>{dateformat(item.created_date, "dd/mm/yyyy")}</span>
                  </div>
                  <p className="news-desc" dangerouslySetInnerHTML={{ __html: item.description }}></p>
              </div>
          )
      }

      if(item && item.id >0){
          return renderHotNews(item);
      }else{
          return (
              <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
          )
      }
  }

  return (
      <div className="container home-news">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6 news_title">
              Tin tức
            </div>
            <div className="col-lg-6 col-md-6 col-6 text-right">
                <div className="see-more">
                    <a href="/tin-tuc">Xem thêm</a>
                </div>
            </div>
            <div className="cb"></div>
          </div>
          <div className="row">
              <div className="col-lg-5 col-md-5 col-12">
                  {renderHotNewsBox(item)}
              </div>
              <div className="col-lg-7 col-md-7 col-12 home-other-news">
                  {listNews && listNews.length >0 ? (
                      listNews.map((item,index) => (
                          index >0 && (
                              renderNewsBox(item,index)
                          )
                      ))
                  ):(
                      <div className="new-item">Chưa có dữ liệu để hiển thị!</div>
                  )}
              </div>
          </div>
      </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(NewsHome));
