/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Collapsible from 'react-collapsible';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as appActions from "../../../core/app.store";
import Category from "./category"
import DropdownAutocomplete from '../../../components/dropdowns/dropdownautocomplete';

function Leftmenu(props) {
    const {
        listCategory,
        onChangeCategory,
        listGovernment,
        onChangeGovernment,
        listProvince,
        defaultProvince,
        onChangeProvince,
        listUpdateFrequency,
        onChangeUpdateFrequency,
        listProjectField,
        onChangeProjectField,
        listProjectType,
        onChangeProjectType,
        listProjectStatus,
        onChangeProjectStatus,
    } = props;

    const buildKeyword = () => {
        return (
            <div className="collapse-item">
                <form>
                    <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder="Nhập từ khóa"></input>
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search"
                                     className="svg-inline--fa fa-search fa-w-16" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill="currentColor"
                                        d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const buildResourceType = () => {
        return (
            <div className="collapse-item">
                <select>
                    <option>Dataset</option>
                    <option>Báo cáo</option>
                    <option>Tài liệu tóm tắt</option>
                    <option>Hướng dẫn</option>
                    <option>Tài liệu cuộc họp - Biên bản</option>
                    <option>Số liệu thống kê</option>
                    <option>Khảo sát</option>
                    <option>Thủ tục</option>
                </select>
            </div>
        )
    }

    const buildReport = () => {
        return (
            <div className="collapse-item">
                <select>
                    <option>Chọn loại báo cáo</option>
                    <option>Báo cáo loại 1</option>
                    <option>Báo cáo loại 2</option>
                </select>
            </div>
        )
    }

    const showResetBox = () => {
        return (
            <div className="priority-refresh-button">
                <svg width={16} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt"
                     className="svg-inline--fa fa-sync-alt fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512">
                    <path fill="currentColor"
                          d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z">
                    </path>
                </svg>
                &nbsp;
                Xóa tất cả các lựa chọn
            </div>
        )
    }


    return (
        <div className="col-md-3">
            {showResetBox()}
            {
                listCategory && listCategory.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Chọn loại quy hoạch">
                        <DropdownAutocomplete listItems={listCategory} onChangeItems={onChangeCategory} />
                    </Collapsible>
                    )
            }
            {
                listGovernment && listGovernment.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Cơ quan">
                        <DropdownAutocomplete listItems={listGovernment} onChangeItems={onChangeGovernment} />
                    </Collapsible>
                )
            }
            {
                listProvince && listProvince.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Địa phương">
                        <DropdownAutocomplete
                            listItems={listProvince}
                            defaultValue={defaultProvince}
                            onChangeItems={onChangeProvince}
                        />
                    </Collapsible>
                )
            }
            {
                listProjectField && listProjectField.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Lĩnh vực">
                        <DropdownAutocomplete listItems={listProjectField} onChangeItems={onChangeProjectField} />
                    </Collapsible>
                )
            }
            {
                listProjectType && listProjectType.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Loại dự án">
                        <DropdownAutocomplete listItems={listProjectType} onChangeItems={onChangeProjectType} />
                    </Collapsible>
                )
            }
            {
                listProjectStatus && listProjectStatus.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Tình trạng dự án">
                        <DropdownAutocomplete listItems={listProjectStatus} onChangeItems={onChangeProjectStatus} />
                    </Collapsible>
                )
            }
            <Collapsible transitionTime="100"  trigger="Từ khóa">
                {buildKeyword()}
            </Collapsible>
            {
                //<Collapsible transitionTime="100" trigger="Loại tài nguyên">
                //    {buildResourceType()}
                //</Collapsible>
            }
            {
                listUpdateFrequency && listUpdateFrequency.length > 0 && (
                    <Collapsible transitionTime="100" trigger="Tần suất cập nhật">
                        <DropdownAutocomplete listItems={listUpdateFrequency} onChangeItems={onChangeUpdateFrequency} />
                    </Collapsible>
                )
            }
            <Collapsible transitionTime="100"  trigger="Báo cáo">
                {buildReport}
            </Collapsible>
        </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Leftmenu));
