export const ApiUrl = {
  //--- Account
  Login: "/api/cms/CmsAccount/Login",
  Register: "api/Account/Register",
  ForgotPassword: "api/Account/ForgotPassword",
  ResetPassword: "api/Account/ResetPassword",
  ChangePassword: "/api/Account/ChangePassword",
  ContactToAdmin: "api/cms/HomePage/ContactToAdmin",
  GetUserAccountDetail: "api/Account/GetUserAccountDetail",
  UpdateUserAccount: "api/Account/UpdateUserAccount",

  //Home Page
  SlideShow: "api/cms/HomePage/GetListPostHomePage",
  HomeIntro: "api/cms/Introduce/GetDetail/planning-introduce",

  //Planning
  GetListStatement: "api/cms/Statement/GetListStatement",
  GetDetailStatement: "api/cms/Statement/GetDetailStatement",
  SearchPlanning: "api/admin/Planning/search-planning-by",
  PlanningType: "api/cms/Planning/get-all-planning-type",
  SearchCategoryByIdName: "api/cms/Planning/search-category-by-id-name",
  ApprovalAgencyLevel:
    "api/admin/ApprovalAgencyLevel/get-approval-agency-level",
  ServiceLink: "api/cms/Statement/GetAllServiceLink",
  SearchStatement: "api/cms/Statement/search-statement-by",
  PlanningTypeById: "api/cms/Planning/get-planning-type-by-id",
  PlanningSearchByIdName: "api/cms/Planning/search-planning-type-by-id-name",
  GetAllPlanningByTypeId: "api/cms/Planning/get-all-planning",
  GetPlanningById: "api/cms/Planning/get-planning-by-id",
  GetMapPlanningById: "api/cms/Planning/get-map-by-planning-id",
  GetPlanningRelationshipByPlanningId:
    "api/cms/Planning/get-planning-relation-by-planning-id",
  GetBoundariesByPlanningId: "api/cms/Planning/get-boundaries-by-planning-id",
  GetYearStatement: "api/cms/Planning/get-all-year-statment",
  GetAllBoundariesOfAllPlanning: "api/cms/Planning/get-all-planning-boundaries",
  GetDetailPlanningById: "/api/cms/Planning/get-planning-by-id",
  GetLookupPlanningKind: "/api/admin/PlanningCategory/GetLookupPlanningKind",

  //--- Map
  GetMapById: "api/admin/Map/get-map-by-id",
  MapFilter: "api/MapTools/MapFilter",
  GetDropDown: "api/MapTools/GetDropDown",
  SpatialSearch: "api/MapTools/SpatialSearch",

  //--- Consultant Community
  GetListConsultantCommunity:
    "api/cms/ConsultantCommunity/get-data-list-consultant-community",
  GetDetailConsultantCommunity:
    "api/cms/ConsultantCommunity/get-detail-consult-community",
  GetDetailFormTemplate: "api/cms/ConsultantCommunity/GetDetailFormTemplate",
  CreateFeedback: "api/cms/ConsultantCommunity/CreateFeedback",
  GetReportFeedback: "api/cms/ConsultantCommunity/GetReportFeedback",
  GetListExpiredForm: "api/cms/ConsultantCommunity/GetListForm",
  GetListResultConsultantCommunity:
    "api/cms/ConsultantCommunity/get-data-list-result-consultant-community",

  //--- News

  GetListNews: "api/cms/News/GetListAll",
  GetListNewsByCategory: "/api​/cms​/News​/GetListByCat",
  GetListNewsByTag: "/api​/cms​/News​/GetListByTag​",
  GetListFeatureNews: "/api​/cms​/News​/GetListFeature​",
  GetListHotNews: "/api/cms/News/GetListHot",
  GetDetailNews: "/api/cms/News/GetDetail",
  SearchNews: "/api/cms/News/Search",

  //--- Videos
  GetListVideos: "/api/cms/Video/GetListAll",

  //--- Notification

  GetListNotification: "api/cms/Notification/GetListAll",
  GetListNotificationByCategory: "/api​/cms​/Notification​/GetListByCat",
  GetListNotificationByTag: "/api​/cms​/Notification​/GetListByTag​",
  GetListFeatureNotification: "/api​/cms​/Notification​/GetListFeature​",
  GetListHotNotification: "/api/cms/Notification/GetListHot",
  GetDetailNotification: "/api/cms/Notification/GetDetail",
  SearchNotification: "/api/cms/Notification/Search",

  // Document
  DocumentType: "api/cms/Planning/get-all-document-type",
  SearchDocument: "api/cms/Document/SearchDocument",
  GetListDocumentByFolder: "api/cms/Document/GetDocumentByFolder",
  GetDocumentById: "api/cms/Document/GetDocumentById",
  GetAllDocumentFolder: "api/cms/Document/ListAllFolder",
  GetCmsLookupDistrict: "api/cms/Administrative/GetLookupDistrict",
  GetDocumentByPlanningId: "api/cms/Planning/get-document-by-planning-id",
  GetChildrenDocument: "api/cms/Planning/get-document-by-planning-id-parent-id",
  DownloadFile: "api/admin/DocumentUpload/DownloadFileBinary",
  GetLookupCommune: "api/admin/Administrative/GetLookupCommune",

  //dropdown list
  GetLookupPlanningCategory: "api/cms/Administrative/GetLookupPlanningCategory",
  GetLookupGovernment: "api/cms/Administrative/GetLookupGovernment",
  GetLookupProjectField: "api/cms/Administrative/GetLookupProjectField",
  GetLookupProjectStatus: "api/cms/Administrative/GetLookupProjectStatus",
  GetLookupProjectType: "api/cms/Administrative/GetLookupProjectType",
  GetLookupResourceType: "api/cms/Administrative/GetLookupResourceType",
  GetLookupUpdateFrequency: "api/cms/Administrative/GetLookupUpdateFrequency",
  GetLookupProvince: "api/Admin/Administrative/GetLookupProvince",

  //Project
  CreateProject: "api/admin/Project/Create",
  GetListProject: "api/cms/Project/GetListAll",
  GetDetailProject: "api/cms/Project/GetDetail",

  //Comment
  CreateComment: "/api/admin/Comment/Create",
  LikeComment: "/api/admin/Comment/Like",
  GetListAllComment: "/api/admin/Comment/GetListAll",
  GetListAllNoPageComment: "/api/admin/Comment/GetListAllNoPage",

  // User Info
  GetRecordsId: "/api/admin/Records/GetListByUser",
  GetRecordsType: "/api/admin/Records/GetListRecordsType",
  GetListRecordsTypeByPlanningKind:
    "/api/admin/Records/GetListRecordsTypeByPlanningKind",
  GetRecordsAll: "/api/admin/Records/GetListAll",
  GetLookupRecordsType: "/api/admin/Records/GetLookupRecordsType",
  GetListRecordsFile: "/api/admin/Records/GetListRecordsFile",
  GetListRecordsType: "/api/admin/Records/GetListRecordsType",
  UploadFileStream: "/api/admin/DocumentUpload/UploadFileStream",
  UpdateRecordFile: "/api/admin/Records/UpdateRecordFile",
  UpdateStep: "/api/admin/Records/UpdateStep",
  GetRecordsDetail: "/api/admin/Records/GetDetail",
  CreateRecords: "/api/admin/Records/Create",
  UpdateRecords: "/api/admin/Records/Update",
  GetLookupPlanningCategoryByPlanningKind: "/api/cms/Administrative/GetLookupPlanningCategoryByPlanningKind/",
  DeleteRecord: "/api/admin/Records/Delete/",

  // Report
  GetPlanningSumaryReport: "api/admin/Records/PlanningSumaryReport",
  GetPlanningKindSumaryReport: "/api/admin/Records/PlanningKindSumaryReport",

  GetAllLogHistory: "/api/admin/ActionLog/GetAllActionLog",
  DeteleRecordFileId: "/api/admin/Records/DeleteFile",
};
