/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./planning.scss";
import * as planningAction from "../../redux/store/planning/planning.store";
import * as documentAction from "../../redux/store/document/document.store";
import * as appActions from "../../core/app.store";
import PublishUnit from "../../components/publish-unit/publish-unit";
import MoreInfo from "./rightmenu/more";
import CommentView from "../../modules/comment/comment.view"
import {
    APIUrlDefault,
} from "../../utils/configuration";
import { TableBody, TableHead } from "@material-ui/core";
import RelatedDocument from "./related";
import config from "../../api/api-config";

function PlanningDetail(props) {
    const { showLoading } = props;
    const { planningId } = props.match.params;
    const [currentPlanning, setCurrentPlanning] = useState();
    const [listPlanning, setListPlanning] = useState([]);
    const [listAssest, setListAssest] = useState([]);
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetPlanningDetail(planningId),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetPlanningDetail = (planningId) => {
        return new Promise((resolve, reject) => {
            planningAction.GetDetailPlanning(planningId).then(
                (res) => {
                    if(res.content){
                        setCurrentPlanning(res.content);
                        onGetPlanningAssest(planningId);

                        if(res.content && res.content.categoryId >0){
                            const options = {}
                            options.pageIndex = 1;
                            options.pageSize = 5;
                            options.categoryId = res.content.categoryId;
                            if(res.content.categoryId == 42 && res.content.provinceId >0){
                                options.provinceId = res.content.provinceId;
                            }
                            onGetListRelatedPlanning(options);
                        }
                    }
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetPlanningAssest = (planningId) => {
        return new Promise((resolve, reject) => {
            documentAction.GetDocumentByPlanningId(planningId).then(
                (res) => {
                    if(res && res.content && Array.isArray(res.content) && res.content.length >0){
                        setListAssest(res.content);
                    }
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListRelatedPlanning = (data) => {
        return new Promise((resolve, reject) => {
            planningAction.GetListPlanning(data).then(
                (res) => {
                    setListPlanning(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const showPageHeader = () => {
        return (
            <div>
                <div className="row link-history">
                    <div className="col-12">
                        <a href="/">Trang chủ</a>
                        &nbsp;&raquo;&nbsp;
                        <span>CSDL quy hoạch</span>
                        &nbsp;&raquo;&nbsp;
                        {
                            currentPlanning && (
                                <span>{currentPlanning.categoryName}</span>
                                )
                        }
                    </div>
                </div>
                
            </div>
        )
    }

    const renderDocumentAssest = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <table width="100%" className="assest-table">
                        <TableHead>
                            <tr>
                                <th width="70%">
                                    Tên tài nguyên
                                </th>
                                <th>Định dạng</th>
                                <th width="15%">Liên kết</th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {currentPlanning && currentPlanning.mapIsActive == true && (
                                <tr>
                                    <td>
                                        {
                                            currentPlanning && (
                                                <span>{currentPlanning.name}</span>
                                            )
                                        }
                                    </td>
                                    <td>GIS</td>
                                    <td>
                                        <div className="download-assest-button">
                                            <a href={`/ban-do-quy-hoach/${planningId}`}>Truy cập</a>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            {listAssest && Array.isArray(listAssest) && listAssest.length >0 && listAssest.map((assest,index) => (
                                <tr key={index}>
                                    <td>
                                        {<span>{assest.title}</span>}
                                    </td>
                                    <td>{assest.extensionName}</td>
                                    <td>
                                        <div className="download-assest-button">
                                            <a href={`${config.api}${assest.path}`}>Tải về</a>
                                        </div>

                                    </td>
                                </tr>
                            ))}
                        </TableBody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            {showPageHeader()}
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div className="row">
                        <div className="col-12">
                            {
                                currentPlanning && (
                                    <div className="priority-header">
                                        {currentPlanning.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        currentPlanning && currentPlanning.statement && currentPlanning.statement.brief && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="planning-desc new-desc" dangerouslySetInnerHTML={{ __html: currentPlanning.statement.brief }}>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        currentPlanning && currentPlanning.files && currentPlanning.files.filePreview && (
                            <div className="row">
                                <div className="col-12 new-image">
                                    <img width="100%"
                                        src={APIUrlDefault + currentPlanning.files.filePreview}
                                        alt={currentPlanning.name}
                                    />
                                </div>
                            </div>
                        )
                    }
                    <div className="row">
                        <div className="col-12">
                            {
                                currentPlanning && currentPlanning.statement && (
                                    <div className="planning-desc new-content" dangerouslySetInnerHTML={{ __html: currentPlanning.statement.content }}>
                                    </div>
                                )
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 assest-header">
                            Tài nguyên
                        </div>
                    </div>
                    {renderDocumentAssest()}
                    {
                        planningId && planningId >0 &&
                        listPlanning && Array.isArray(listPlanning) && listPlanning.length >0 && (
                            <RelatedDocument
                                currentPlanningId = {planningId}
                                listPlanning = {listPlanning}
                            />
                        )
                    }

                    <CommentView
                        referrenceId={planningId}
                        referrenceType="PLANNING"
                    />
                    <div className="h-30"></div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <MoreInfo
                        planning={currentPlanning}
                    />
                    <PublishUnit />
                </div>
            </div>
        </div>
      );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(PlanningDetail));
