import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import * as reportAction from "./../../../redux/store/report/report.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import { NotificationMessageType } from "../../../utils/configuration";
import "./report-planning-project.scss";
import UrlCollect from "../../../common/url-collect";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        borderDash: [3, 3],
      },
      ticks: {
        beginAtZero: true,
      },
      barPercentage: 0.3,
    },
  },
};

function ReportPlanningProject(props) {
  const [dataReport, setDataReport] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  const [dataFill, setDataFill] = useState([]);
  useEffect(() => {
    reportAction
      .GetPlanningSumaryReport()
      .then((res) => {
        if (res && res.content && res.content) {
          setDataFill(res.content);
          setDataLabels(res.content?.map((item) => item.name))
          setDataReport(res.content?.map((item) => item.count))
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
  }, []);
  const labels = dataLabels;

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataReport,
        backgroundColor: "rgba(28, 64, 159, 1)",
        borderWidth: 0,
        barThickness: 40,
      },
    ],
  };
  return (
    <div className="report-planning-project-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title">1. Báo cáo tình hình lập quy hoạch</div>
        <a className="link" href={UrlCollect.PlanningReport}>Xem chi tiết</a>
      </div>
      <div className="row content">
        <div className="col-md-8">
          {" "}
          <Bar options={options} data={data} />
        </div>
        <div className="col-md-4 content-right">
         
            {
                dataFill.map((item) => (
                    <div className="d-flex justify-content-between item" key={item.id}>
                    <div className="d-flex label">
                      <div
                        className="color"
                        style={{ backgroundColor: "rgba(28, 64, 159, 1)" }}
                      ></div>
                      <div className="text">{item.name}:</div>
                    </div>
                    <div className="value">{item.count}</div>
                  </div>
                ))
            }
        </div>
      </div>
    </div>
  );
}

export default ReportPlanningProject;
