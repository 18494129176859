import React, { Fragment } from "react";
import "./planning-map-view.scss";
import ViLanguages from "../../languages/vi";
import GuideUseMap from "./guide-use-map/guide-use-map.view";
import Preview from "./left-menu/preview/preview";
import TopRightPanelView from "./top-right-panel/top-right-panel.view";
import MapToolsPanel from "./map-tools-panel/map-tools-panel.view.jsx";
import LeftMenuView from "./left-menu/left-menu.view";
import RenderFilterInfomationPopup from "./filter-infomation/filter-infomation.view.jsx";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import PlanningSearch from "./planning-search/search";
import $ from 'jquery';
import {ListBaseMap} from "../../api/api-service";
import {
  ExtractCenterArray,
  ExtractExtentArray,
  ExtractListLayerArray,
  ExtracLayerListGroup,
} from "../../common/extract-information-from-map-data";
import LayerSettingsModels from "../../models/map-data-model-b/layer-setting-models";
import * as projectAction from "../../redux/store/project/project.store";
class PlanningMapView extends React.Component<
  PlanningMapViewModel.PlanningMapViewProps,
  PlanningMapViewModel.PlanningMapViewState
  > {
  constructor(props: PlanningMapViewModel.PlanningMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: null,
      isLeftNavbarHide: false,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null,
      evt:null,
      project: {},
      location:{lat:'',lng:'',searchType:''}
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(
      this
    );
  }

  componentDidMount() {
    if(this.props.match.params.lat && this.props.match.params.lng){
      this.setState({
        location:{
          lat:this.props.match.params.lat,
          lng:this.props.match.params.lng,
          searchType:'place',
        }
      })
    }

    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    if(this.props.match.params.projectId && this.props.match.params.projectId >0
      && this.props.match.params.layerId && this.props.match.params.layerId >0
    ){
      this.getDefaultProject(this.props.match.params.projectId,this.props.match.params.layerId)
    }else{
      this.props.GetMapDetail(this.props.match.params.id)
    }

  }

  handleClickIconLeftNavbar(id: number) {
    this.handleClickToggleLeftNavBar();
    this.handleClickToggleLeftNavBar();
    const buttonElement = document.querySelector(
      `button[aria-controls="${id}"]`
    ) as HTMLElement;
    if (buttonElement && buttonElement.classList.contains("collapsed"))
      buttonElement.click();
  }

  handleClickToggleLeftNavBar() {
    this.setState({
      isLeftNavbarHide: !this.state.isLeftNavbarHide,
    });
    if (!this.state.isLeftNavbarHide) {
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.add("hide-left-navbar");
    } else
      document
        .getElementsByClassName("left-custom-navbar")[0]
        .classList.remove("hide-left-navbar");
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  manualCloseMaptoolsFilterInfomationPopup = () => {
    this.setState({ isShowFilterInfomationPopup: false });
  };
  setPlanningMap = (_planningModel: any) => {
    this.setState({planningModel: _planningModel});
  }

  handlePlanningSearch = (layer:LayerSettingsModels.LayerSettingsModel,itemList:any) => {
    const layerId = (layer && layer.id) || 0;
    if(itemList && Array.isArray(itemList) && itemList.length >0 && layerId >0){
      itemList.map((item) => {
        if(item && item.id && item.id >0 && item.id == layerId){
          item.is_check = true;
          $('#map-item-'+item.id).prop('checked',true);
        }else{
          item.is_check = false;
          $('#map-item-'+item.id).prop('checked',false);
        }
        this.props.openLayerController?.toggleDisplayLayer(item);

      })
    }
  }

  getDefaultProject = (projectId:number,layerId:number) => {
    if(projectId >0) {
      return new Promise((resolve, reject) => {
        projectAction.GetDetailProject(projectId).then(
            (res) => {
              if(res && res.content && res.content.layerObject){
                this.setState({
                  project:res.content,
                  evt:JSON.parse(res.content.layerObject)
                })
                resolve(JSON.parse(res.content.layerObject));
              }
            },
            (err) => {
              reject(err);
            }
        );
      }).then(() => {
        return this.props.GetMapDetail(this.props.match.params.id)
      }).then((mapData) => {
        if(mapData){
          $('#map-item-'+layerId).prop('checked',true);
        }
      });
      }
    }

  render() {
    const isLeftNavbarHide = this.state.isLeftNavbarHide;
    // const isShowMapToolsPanel = this.state.isShowMapToolsPanel;

    return (
      <div className="planning-map-view-container position-relative">
        <div className="row planning-top-box">
            <div className="col-md-3 planning-top-search">
                <PlanningSearch
                    mapData={this.props.mapData.map_setting[1]}
                    onChangeSearch={this.handlePlanningSearch}
                />
            </div>
            <div className="col-md-6 planning-top-name">
                {this.props.mapData.name}
            </div>
        </div>
        {/* top right panel*/}

        <TopRightPanelView
          baseMapList={ListBaseMap}
          planningId={this.props.match.params.id}
          setPlanningModelInMap={this.setPlanningMap}
        />

        {/* map tool panel control */}

        <div className="position-absolute map-tools-container">
          <div className="position-relative">
            <RenderFilterInfomationPopup
                listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                listLayer={ExtractListLayerArray(this.props.mapData)}
                planningId={this.props.match.params.id}
                boundaries
            />
          </div>
          <MapToolsPanel
            openFIlterPopup={this.openFilterInfomationPopup}
            map={this.state.mapPlanning}
          />
        </div>
        {/* open layer view port */}
        {this.props.hasLoadedMapData && this.props.mapData.name && (
          <OpenlayersMapView
            baseMapType = "bdvn-vn2000"
            defaultCenter={ExtractCenterArray(this.props.mapData)}
            defaultZoom={this.props.mapData.zoom}
            projection={this.props.mapData.projection}
            maxZoom={this.props.mapData.max_zoom}
            minZoom={this.props.mapData.min_zoom}
            extent={ExtractExtentArray(this.props.mapData)}
            listLayer={ExtractListLayerArray(this.props.mapData)}
            planningModel ={this.state.planningModel}
            listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
            defaultEvt={this.state.evt}
            defaultProject={this.state.project}
            location={this.state.location}
          />
        )}
        {this.props.hasLoadedMapData && !this.props.mapData.name && (
          <div
            className="modal d-block"
            id="modalNotHaveDataToDisplay"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Dữ liệu không có sẵn để hiển thị
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lỗi: Bản đồ của dự án chưa được khởi tạo, vui lòng liên hệ với
                  Admin
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* left nav bar */}
        <div className="left-custom-navbar left-custom-navbar-mt position-absolute">
          {this.props.hasLoadedMapData && this.props.mapData.name && (
            <Fragment>
              <LeftMenuView
                planningId={this.props.match.params.id}
                listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                isLeftNavbarHide={isLeftNavbarHide}
                toggleStateIsLeftNavbarHide={() =>
                  this.setState({
                    isLeftNavbarHide: !this.state.isLeftNavbarHide,
                  })
                }
              />
            </Fragment>
          )}
        </div>

        <GuideUseMap
            modalHeightStyle = {this.state.modalHeightStyle}
        />
        <Preview />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanningMapView);
