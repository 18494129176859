/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import "./planning.scss";
import * as planningActions from "../../redux/store/planning/planning.store";
import * as dropdownActions from "../../redux/store/dropdownlist/dropdownlist.store";
import * as appActions from "../../core/app.store";
import UrlCollect from "../../common/url-collect";
import Pagination from "../../components/pagination/pagination.view";
import Leftmenu from "./leftmenu/leftmenu";
import dateformat from "dateformat";

function Planning(props) {
    const { showLoading } = props;
    const [pageCount, setPageCount] = useState();
    const [listPlanning, setListPlanning] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const pageSize = 10;
    //const { categoryId } = props.match.params;
    const [categoryId, setCategoryId] = useState(props.match.params.categoryId);
    const [listCategory, setListCategory] = useState([]);
    const [listGovernment, setListGovernment] = useState([]);
    const [governmentId, setGovernmentId] = useState();
    const [listProvince, setListProvince] = useState([]);
    const [defaultProvince, setDefaultProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(0);
    const [listUpdateFrequency, setListUpdateFrequency] = useState([]);
    const [updateFrequencyId, setUpdateFrequencyId] = useState();
    const [keyword, setKeyword] = useState();

    const options = {pageIndex: 1, pageSize: pageSize, categoryId: props.match.params.categoryId}
    if(props.match.params.provinceId >0){
        options.provinceId = props.match.params.provinceId;
    }

    const [dataSearch, setDataSearch] = useState(options);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListPlanning(dataSearch),
            onGetListCategory(props.match.params.categoryId),
            onGetListGovernment(),
            onGetListProvince(),
            onGetListUpdateFrequency(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListCategory = (catId) => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListPlanningCategory(catId).then(
                (res) => {
                    console.log(res);
                    setListCategory(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListGovernment = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListGovernment().then(
                (res) => {
                    setListGovernment(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListProvince = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListProvince().then(
                (res) => {
                    if(res && res.content && res.content.length > 0){
                        const listProvince      = res.content;
                        const defaultProvinceId = props.match.params.provinceId;
                        if(defaultProvinceId >0){
                            listProvince.map((province) => {
                                if(province.id == defaultProvinceId){
                                    setDefaultProvince(province);
                                }
                            })
                        }
                        setListProvince(listProvince);

                    }
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
    const onGetListUpdateFrequency = () => {
        return new Promise((resolve, reject) => {
            dropdownActions.GetListUpdateFrequency().then(
                (res) => {
                    setListUpdateFrequency(
                        res &&
                            res.content &&
                            res.content.length > 0 ? res.content : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onChangeCategory = (newValue) => {
        dataSearch.categoryId = (newValue && newValue.id) ||  props.match.params.categoryId;
        onGetListPlanning(dataSearch);
        setCategoryId(newValue && newValue.id || props.match.params.categoryId);
    }

    const onChangeGovernment = (newValue) => {
        dataSearch.governmentId = (newValue && newValue.id) || null;
        onGetListPlanning(dataSearch);
        setGovernmentId((newValue && newValue.id) || null)
    }

    const onChangeProvince = (newValue) => {
        dataSearch.provinceId = (newValue && newValue.id) || null;
        onGetListPlanning(dataSearch);
        setProvinceId((newValue && newValue.id) || null)
    }

    const onChangeUpdateFrequency = (newValue) => {
        dataSearch.updateFrequencyId = (newValue && newValue.id) || null;
        setUpdateFrequencyId((newValue && newValue.id) || null);
        onGetListPlanning(dataSearch);
    }

    const onSearchClick = ()=>
    {
        onGetListPlanning(dataSearch);
    }
    
    const onGetListPlanning = (data) => {
        return new Promise((resolve, reject) => {
            planningActions.GetListPlanning(data).then(
                (res) => {
                    setListPlanning(
                        res &&
                        res.content &&
                        res.content.items
                        && res.content.items.length > 0 ? res.content.items: []
                    );
                    setTotalItemCount(
                        res && res.content && res.content.totalItemCount
                            ? res.content.totalItemCount
                            : 0
                    );
                    setPageCount(
                        res && res.content && res.content.pageCount
                            ? res.content.pageCount
                            : 0
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onPagingClick = (data, isClearSearch = false) => {
        dataSearch.pageIndex = data.pageIndex;
        onGetListPlanning(dataSearch);
    };

    const onKeywordKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSearchClick();
        }
    }

    const showSearchBox = () => {
        return (
            <div className="row">
                <div className="col-md-6 col-12"></div>
                <div className="col-md-6 col-12 text-right">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Nhập tên CSDL"
                            onChange={(e) => { dataSearch.keyword=e.target.value }} onKeyDown={(e) => { onKeywordKeyDown(e)}}
                               aria-label="Input group example" aria-describedby="btnGroupAddon" />
                        <div className="input-group-prepend">
                            <div className="input-group-text priority-search-button" id="btnGroupAddon" onClick={() => onSearchClick()}>Tìm kiếm</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const getDetailUrl = (planning) => {
        let url = '';
        if(planning.planningTypeId === 1000){
            url = UrlCollect.PlanningDocument + "/" + planning.id;
        }else{
            url = UrlCollect.DetailedPlanning + "/" + planning.id;
        }
        return url;
    }

    const showListPlanning = (planning,index) => {
        return (
            <div className="row" key={index}>
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="priority-search-result">
                        <div className="row">
                            <div className="col-md-12  planning-title">
                                <a href={getDetailUrl(planning)}>{planning.name}</a>
                            </div>
                        </div>
                        {planning.note && (
                            <div className="row">
                                <div className="col-md-12 planning-desc">
                                    <p className="new-desc" dangerouslySetInnerHTML={{ __html: planning.note }}></p>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-6 col-12 planning-more-info">
                                <span>Cập nhật lần cuối:</span> {dateformat(planning.publishDate, "dd/mm/yyyy")}
                            </div>
                            <div className="col-md-6 col-12 planning-more-info">
                                <span>Ngày xuất bản:</span> {dateformat(planning.publishDate, "dd/mm/yyyy")}
                            </div>
                        </div>
                        {planning.governmentName && (
                            <div className="row">
                                <div className="col-md-6 col-12 planning-more-info">
                                    <span>Tên tổ chức:</span> {planning.governmentName}
                                </div>
                                <div className="col-md-6 col-12 planning-more-info">

                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-md-12 planning-tag">
                                {planning.tags && Array.isArray(planning.tags) && planning.tags.length >0 && (
                                    planning.tags.map((tag,index) => (
                                        <span key={index}>{tag}</span>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const showPaging = () => {
        return (
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
                    {listPlanning && listPlanning.length > 0 && (
                        <div className="list-footer">
                            <div
                                className={`text-center text-result ${
                                    pageCount && pageCount > 1 ? "float-left" : ""
                                }`}
                            >
                                <span>Có {totalItemCount} kết quả</span>
                            </div>
                            {pageCount && pageCount > 1 && (
                                <div className="float-right">
                                    <Pagination
                                        totalItems={totalItemCount}
                                        currentIndex={currentPageIndex}
                                        pageSize={pageSize}
                                        onClick={(pageIndex) => {
                                            onPagingClick({
                                                pageIndex: pageIndex
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const showPageHeader = (categoryId) => {
        let categoryName;
        let categoryHeader;
        switch(categoryId){
            case "1":
                categoryName = "Quy hoạch tổng thể quốc gia";
                categoryHeader = "Quy Hoạch Tổng Thể Quốc Gia";
                break;
            case "2":
                categoryName = "Quy hoạch không gian biển quốc gia";
                categoryHeader = "Quy Hoạch Không Gian Biển Quốc Gia";
                break;
            case "3":
                categoryName = "Quy hoạch sử dụng đất quốc gia";
                categoryHeader = "Quy Hoạch Sử Dụng Đất Quốc Gia";
                break;
            case "4":
                categoryName = "Quy hoạch ngành quốc gia";
                categoryHeader = "Quy Hoạch Ngành Quốc Gia";
                break;
            case "5":
                categoryName = "Quy hoạch vùng";
                categoryHeader = "Quy Hoạch Vùng";
                break;
            case "7":
                categoryName = "QHV đồng bằng sông Cửu Long";
                categoryHeader = "Quy hoạch vùng Đồng Bằng Sông Cửu Long";
                break;
            case "42":
                categoryName = "Quy hoạch tỉnh";
                categoryHeader = "Quy Hoạch Tỉnh";
                break;
            case "43":
                categoryName = "Quy hoạch đơn vị hành chính - kinh tế đặc biệt";
                categoryHeader = "Quy Hoạch Đơn Vị Hành Chính - Kinh Tế Đặc Biệt";
                break;
            case "44":
                categoryName = "Quy hoạch đô thị - nông thôn";
                categoryHeader = "Quy Hoạch Đô Thị - Nông Thôn";
                break;
            case "45":
                categoryName = "Quy hoạch có tính chất kỹ thuật, chuyên ngành";
                categoryHeader = "Quy hoạch có tính chất kỹ thuật, chuyên ngành";
                break;
            default:
                categoryName = "";
                categoryHeader = "";
                break;
        }
        return (
            <div>
                <div className="row link-history">
                    <div className="col-12">
                        <a href="/">Trang chủ</a>
                        &nbsp;&raquo;&nbsp;
                        <span>CSDL quy hoạch</span>
                        &nbsp;&raquo;&nbsp;
                        <span>{categoryName}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="priority-header">
                            {categoryHeader}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            {
                categoryId && categoryId >0 && (
                    showPageHeader(categoryId)
                )
            }
            <div className="row">
                <Leftmenu
                    listCategory={listCategory}
                    onChangeCategory={onChangeCategory}
                    listGovernment={listGovernment}
                    onChangeGovernment={onChangeGovernment}
                    listProvince={listProvince}
                    defaultProvince = {defaultProvince}
                    onChangeProvince={onChangeProvince}
                    listUpdateFrequency={listUpdateFrequency}
                    onChangeUpdateFrequency={onChangeUpdateFrequency}
                />
                <div className="col-md-9">
                    {showSearchBox()}
                    {
                        listPlanning && listPlanning.length > 0 && (
                            listPlanning.map((planning,index) => showListPlanning(planning,index))
                        )
                    }
                    {showPaging()}
                </div>
            </div>
        </div>
      );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(Planning));
