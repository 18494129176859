import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useForm, Controller } from 'react-hook-form';
import {
  IconButton,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import "./planning-provincial.scss";
import * as planningAction from "./../../redux/store/planning/planning.store";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "./../../languages/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import MuiSelect from '../../components/select';

const DEFAULT_PAGE_SIZE = 50;

function PlanningProvincial(props) {
    const [yearData, setYearData] = useState([])

    const [planningStatusOptions] = useState([
      {
        label: 'Tất cả',
        value: null,
      },
      {
        label: 'Chưa nộp',
        value: 1,
      },
      {
        label: 'Đã nộp',
        value: 2,
      },
    ]);
  
    const [planningProcessOptions] = useState([
      {
        label: 'Tất cả',
        value: null,
      },
      {
        label: 'Đã tiếp nhận',
        value: 3,
      },
      {
        label: 'Đang phê duyệt',
        value: 4,
      },
      {
        label: 'Đã công bố',
        value: 5,
      },
    ]);

    const [queryParams, setQueryParams] = useState({
      pageIndex: 0,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    const [tableData, setTableData] = useState({
      records: [],
      total: 0,
    });
    const { handleSubmit, control } = useForm();


    const columns = [
      {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
      },
      {
        title: 'Tên địa phương',
        dataIndex: 'provinceName',
        key: 'provinceName',
      },
      {
        title: 'Tên quy hoạch',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Trạng thái nộp',
        dataIndex: 'recordsStatus',
        key: 'recordsStatus',
      },
      {
        title: 'Tình trạng',
        dataIndex: 'recordsTypeName',
        key: 'recordsTypeName',
      },
      {
        title: 'Ngày nộp',
        dataIndex: 'modified_date',
        key: 'modified_date',
      },
      {
        title: 'Trạng thái xử lý',
        dataIndex: 'status2',
        key: 'status2',
      },
    ];


    const getData = useCallback(() => {
      const { name, year, planningProcess, planningStatus, pageIndex, pageSize } = queryParams;
  
      let param = `?PlanningKindId=1&Year=${year}`;
      if (year) {
        param = param + `&Year=${year}`;
      }
      if (name) {
        param = param + `&Name=${name}`;
      }
      if(planningStatus && planningProcess) {
        param = param + `&RecordsStatus=${planningProcess}`;
      } else {
        param = param + `&RecordsStatus=${planningStatus || planningProcess}`;
      }

      if(pageIndex) {
        param = param + `&pageIndex=${pageIndex + 1}`
      }
      if(pageSize) {
        param = param + `&pageSize=${pageSize}`
      }
      planningAction
      .GetRecordsAll(param)
      .then((res) => {
        if (res && res.content && res.content) {
          const newData = res.content.items.map((item, index) => ({
            index: res.content.pageSize * (res.content.pageIndex - 1) + index + 1,
            provinceName: item.provinceName || "-",
            name: <a href={`/thong-tin-quy-hoach/${item.id}`}>{item.name}</a>,
            recordsTypeName: item.recordsTypeName || "-",
            recordsStatus: renderLabel1(item.recordsStatus),
            modified_date: item.recordsStatus <= 1 ? "-" : moment(new Date(item.modified_date)).format("DD/MM/yyyy"),
            status2: renderLabel2(item.recordsStatus),
          }));

          setTableData({
            records: newData,
            total: res.content.totalItemCount,
          });
        }
      })
      .catch((err) => {
        err &&
          err.errorType &&
          ShowNotification(
            viVN.Errors[err.errorType],
            NotificationMessageType.Error
          );
      });
    }, [queryParams]);

    const onSubmit = (data) => {
      setQueryParams({
        ...data,
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    };
  
    const handleChangePage = (event, newPage) => {
      setQueryParams((prevState) => ({
        ...prevState,
        pageIndex: newPage,
      }));
    };
  
    const handleChangeRowsPerPage = (event) => {
      const { value } = event.target;
  
      setQueryParams({
        pageIndex: 0,
        pageSize: parseInt(value),
      });
    };

    useEffect(() => {
       const yearFake = new Date().getFullYear();
       const tmp = [];
       for (let index = yearFake - 5; index < yearFake + 10; index++) {
          tmp.push({
            label: index,
            value: index,
          })
       }
       setYearData([
          {
            label: 'Tất cả',
            value: null,
          },
          ...tmp,
        ]);
    }, []);

    useEffect(() => {
      getData()
    }, [getData]);

    const renderLabel1 = (value) => {
        if(value > 2) {
            return "Đã nộp"
        }
        if(value === 2) {
            return "Đã nộp"
        }
        if(value === 1) {
            return "Chưa nộp"
        }
        return "-"
    }
    const renderLabel2 = (value) => {
        if(value <= 2) {
            return "-"
        }
        if(value === 3) {
            return "Đã tiếp nhận";
        }
        if(value === 4) {
            return "Đang phê duyệt";
        }
        if(value === 5) {
            return "Đã công bố";
        }
        return "-"
    }
  return (
    <div className="container planning-provincial-wrapper">
      <div className="d-flex header-planning">
        <div className="title">Quy hoạch cấp Tỉnh</div>
      </div>
      <div className="d-flex form">
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off" className="d-flex form">
          <Controller
            as={TextField}
            name="name"
            control={control}
            label="Tên quy hoạch"
            defaultValue=""
            variant="outlined"
          />
          <Controller 
            as={MuiSelect} 
            control={control} 
            name="year"
            label="Năm" 
            defaultValue={null} 
            options={yearData} 
            className="select-year"/>
          <Controller
            as={MuiSelect}
            control={control}
            name="planningStatus"
            label="Trạng thái nộp hồ sơ"
            defaultValue={null}
            options={planningStatusOptions}
          />
          <Controller
            as={MuiSelect}
            control={control}
            name="planningProcess"
            label="Trạng thái xử lý hồ sơ"
            defaultValue={null}
            options={planningProcessOptions}
          />

          <IconButton type="submit" variant="contained" color="primary" className="button-search">
            <SearchIcon />
          </IconButton>
        </form>
      </div>
      <div className="content-planning">
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                {columns.map((item, index) => (
                  <TableCell key={index}>{item.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!tableData.total &&
                tableData.records.map((item, index) => (
                  <TableRow key={index}>
                    {columns.map((column, index) => (
                      <TableCell component="th" scope="row" key={index}>
                        {item[column.dataIndex]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {!tableData.total && (
                <TableRow>
                  <TableCell colSpan={12} align="center">
                    Chưa có dự án
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  colSpan={12}
                  count={tableData.total}
                  rowsPerPage={queryParams.pageSize}
                  page={queryParams.pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default PlanningProvincial;
