/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./planning.scss";
import * as planningActions from "../../redux/store/planning/planning.store";
import * as appActions from "../../core/app.store";
import PublishUnit from "../../components/publish-unit/publish-unit";
import MoreInfo from "./rightmenu/more";
import RelatedDocument from "./related"
import config from "../../api/api-config"
import {
    APIUrlDefault,
} from "../../utils/configuration";
import { TableBody, TableHead } from "@material-ui/core";

function PlanningDocument(props) {
    const { showLoading } = props;
    const { planningId } = props.match.params;
    const [document, setDocument] = useState();
    const [listPlanning, setListPlanning] = useState([]);
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetDocumentById(planningId),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetDocumentById = (id) => {
        return new Promise((resolve, reject) => {
            planningActions.GetDocumentById(id).then(
                (res) => {
                    console.log(res.content);
                    setDocument(
                        res.content
                    );
                    onGetListPlanning({ pageIndex: 1, pageSize: 5, categoryId: res.content && res.content.categoryId});
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const onGetListPlanning = (data) => {
        return new Promise((resolve, reject) => {
            planningActions.GetListPlanning(data).then(
                (res) => {
                    setListPlanning(
                        res &&
                        res.content &&
                        res.content.items
                        && res.content.items.length > 0 ? res.content.items : []
                    );
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const showPageHeader = () => {
        return (
            <div>
                <div className="row link-history">
                    <div className="col-12">
                        <a href="/">Trang chủ</a>
                        &nbsp;&raquo;&nbsp;
                        <span>CSDL quy hoạch</span>
                        &nbsp;&raquo;&nbsp;
                        {
                            document && (
                                <span>{document.categoryName}</span>
                                )
                        }
                    </div>
                </div>
                
            </div>
        )
    }

    const renderDocumentAssest = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <table width="100%" className="assest-table">
                        <TableHead>
                            <tr>
                                <th>
                                    Tên tài nguyên
                                </th>
                                <th>Loại tài nguyên</th>
                                <th>Định dạng</th>
                                <th>Ngôn ngữ</th>
                                <th></th>
                            </tr>
                        </TableHead>
                        <TableBody>
                            {
                                document && (
                                    <tr>
                                        <td>
                                            {<span>{document.name}</span>}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            <a href={`${config.api}${document.path}`}
                                               target="_blank"
                                               className="input-group-text priority-search-button">Download</a>
                                        </td>
                                    </tr>
                                )
                            }
                        </TableBody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            {console.log(document)}
            {showPageHeader()}
            <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                    <div className="row">
                        <div className="col-12">
                            {
                                document && (
                                    <div className="priority-header">
                                        {document.name}
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        document && document.description && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="planning-desc new-desc" dangerouslySetInnerHTML={{ __html: document.description }}>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        document && document.content && (
                        <div className="row">
                            <div className="col-12">
                                <div className="planning-desc new-content" dangerouslySetInnerHTML={{ __html: document.content }}>
                                </div>

                            </div>
                        </div>
                        )
                    }
                    <div className="row">
                        <div className="col-12 assest-header">
                            Tài nguyên
                        </div>
                    </div>
                    {renderDocumentAssest()}
                    <RelatedDocument listPlanning = {listPlanning} />
                    <div className="h-30"></div>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                    <MoreInfo
                        document={document}
                    />
                    <PublishUnit/>
                </div>
            </div>
        </div>
      );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
        showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(PlanningDocument));
