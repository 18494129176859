import React, {useEffect, useState} from "react";
import "./province-leftmenu.scss";
import { connect } from "react-redux";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import {bindActionCreators} from "redux";
import * as appActions from "../../../core/app.store";
import * as dropdownAction from "../../../redux/store/dropdownlist/dropdownlist.store";
import notification from "../../../components/react-notifications/helpers/notification";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import {NotificationMessageType, NotificationPosition} from "../../../utils/configuration";

function ProvinceLeftmenu(props) {
    const { showLoading } = props;
    const [provinceList,setProvinceList] = useState([]);

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListProvince(),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListProvince = () => {
        return new Promise((resolve, reject) => {
            dropdownAction.GetListProvince().then(
                (res) => {
                    let items = [];
                    if(res && res.content && res.content.length >0 && (
                        res.content.map((item) => {
                            item.title = item.name;
                            items.push(item);
                        })
                    ))
                    setProvinceList(items);
                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    const handleOnSearch = (string, results) => {
        // onSearch will have as the first callback parameter
        // the string searched and for the second the results.
        //console.log(string, results)
    }

    const handleOnHover = (result) => {
        // the item hovered
        //console.log(result)
    }

    const handleOnSelect = (item) => {
        if(item.defaultPlanningId && item.defaultPlanningId >0){
            window.location = "/ban-do-quy-hoach/"+item.defaultPlanningId;
        }else{
            ShowNotification(
                viVN.Errors.NoDataForDisplay,
                NotificationMessageType.Warning,
                NotificationPosition.Center
            );
        }
    }

    const handleOnFocus = () => {
        //console.log('Focused')
    }
    
    const buildProvinceSearch = () => {
        return (
            <div className="collapse-item">
                <ReactSearchAutocomplete
                    styling={{
                        height: "30px",
                        border: "1px solid #dfe1e5",
                        borderRadius: "2px",
                        backgroundColor: "#FFFFFF",
                        boxShadow: "rgba(32, 33, 36, 0.28) 0px 1px 6px 0px",
                        hoverBackgroundColor: "#eee",
                        color: "#212121",
                        fontSize: "16px",
                        fontFamily: "Arial",
                        iconColor: "grey",
                        lineColor: "rgb(232, 234, 237)",
                        placeholderColor: "grey",
                        clearIconMargin: '3px 14px 0 0',
                        searchIconMargin: '0 0 0 16px',
                    }}
                    items={provinceList}
                    placeholder="Tỉnh/Thành phố"
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                />
            </div>
        )
    }

    const buildProvinceList = () => {
        return (
            <div className="province-list-box">
                {provinceList && Array.isArray(provinceList) && provinceList.length >0 && (
                    provinceList.map((province,index) => (
                        <div className="province-item" key={index}>
                            {province.defaultPlanningId && province.defaultPlanningId >0 ? (
                                <a href={`/ban-do-quy-hoach/${province.defaultPlanningId}`} title={province.name}>{province.name}</a>
                            ):(
                                <a href="#" title={province.name}>{province.name}</a>
                            )}
                        </div>
                    ))
                )}
                <div className="cb"></div>
            </div>
        )
    }

  return (
    <div className="left-menu-custom-container h-100 position-relative province-planning">
      <div className="left-area-title">
        Quy hoạch tỉnh
      </div>
      <div className="province-search-box">
          {buildProvinceSearch()}
      </div>
      {buildProvinceList()}
        <a href="/quy-hoach/42">
            <div className="related-button">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
                     className="svg-inline--fa fa-arrow-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                </svg>
                &nbsp;
                <span>Cơ sở dữ liệu liên quan </span>
            </div>
        </a>
    </div>
  );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(ProvinceLeftmenu));
