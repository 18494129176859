import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListPlanningCategory = (categoryId) => {
  const params = new URLSearchParams();
  params.append("parentId", categoryId);

  return service
      .get(ApiUrl.GetLookupPlanningCategory, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetListGovernment = () => {
    return service
        .get(ApiUrl.GetLookupGovernment)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListProvince = () => {
    return service
        .get(ApiUrl.GetLookupProvince)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListUpdateFrequency = () => {
    return service
        .get(ApiUrl.GetLookupUpdateFrequency)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListProjectField = () => {
    return service
        .get(ApiUrl.GetLookupProjectField)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListProjectType = () => {
    return service
        .get(ApiUrl.GetLookupProjectType)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const GetListProjectStatus = () => {
    return service
        .get(ApiUrl.GetLookupProjectStatus)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};