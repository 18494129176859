import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetListHotPlanning = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);

  data.id && params.append("id", data.id);
  data.title && params.append("title", data.title);
  data.categoryId && params.append("categoryId", data.categoryId);
  data.image_Url && params.append("image_Url", data.image_Url);
  data.name && params.append("name", data.name);
  data.status && params.append("status", data.status);
  data.description && params.append("description", data.description);
  data.content && params.append("content", data.content);
  data.createdDate && params.append("createdDate", data.createdDate);
  data.modifiedDate && params.append("modifiedDate", data.modifiedDate);

  return service
      .get(ApiUrl.GetListHotPlanning, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const SearchPlanning = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("keyword", data.keyword);
  params.append("pageSize", data.pageSize);

  data.id && params.append("id", data.id);
  data.title && params.append("title", data.title);
  data.categoryId && params.append("categoryId", data.categoryId);
  data.image_Url && params.append("image_Url", data.image_Url);
  data.name && params.append("name", data.name);
  data.status && params.append("status", data.status);
  data.description && params.append("description", data.description);
  data.content && params.append("content", data.content);
  data.createdDate && params.append("createdDate", data.createdDate);
  data.modifiedDate && params.append("modifiedDate", data.modifiedDate);

  return service
      .get(ApiUrl.SearchPlanning, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetListPlanning = (data) => {
    const params = new URLSearchParams();
    params.append("categoryId", data.categoryId);
    params.append("pageIndex", data.pageIndex);
    params.append("pageSize", data.pageSize);
    data.governmentId && params.append("governmentId", data.governmentId);
    data.updateFrequencyId && params.append("updateFrequencyId", data.updateFrequencyId);
    data.provinceId && params.append("provinceId", data.provinceId);
    data.keyword && params.append("name", data.keyword);

  return service
      .get(ApiUrl.SearchCategoryByIdName, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetDetailPlanning = (id) => {
    let requestUrl = ApiUrl.GetDetailPlanningById + '?id=' +id;

  return service
    .get(requestUrl)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetDocumentById = (id) => {
  let requestUrl = ApiUrl.GetDocumentById + '?id=' +id;

  return service
      .get(requestUrl)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
};

export const GetDetailFormTemplate = (id) => {
  const params = new URLSearchParams();
  params.append("PlanningId", id);

  return service
    .get(ApiUrl.GetDetailFormTemplate, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const CreateFeedback = (data) => {
  return service
    .post(ApiUrl.CreateFeedback, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetListExpiredForm = (data) => {
  const params = new URLSearchParams();
  params.append("pageIndex", data.pageIndex);
  params.append("pageSize", data.pageSize);
  data.sortExpression && params.append("sortExpression", data.sortExpression);
  data.title && params.append("title", data.title);
  data.PlanningId &&
    params.append("PlanningId", data.PlanningId);

  return service
    .get(ApiUrl.GetListExpiredForm, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetReportFeedback = (id) => {
  const params = new URLSearchParams();
  params.append("templateId", id);

  return service
    .get(ApiUrl.GetReportFeedback, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetRecordsAll = (params) => {
  return service.get(ApiUrl.GetRecordsAll + params).then((res) => {return res}).catch((err) => { throw err });
}
export const GetLookupRecordsType = () => {
  return service.get(ApiUrl.GetLookupRecordsType).then((res) => {return res}).catch((err) => { throw err });
}
export const getRecordDetail = (id) => {
  return service.get(`${ApiUrl.GetRecordsDetail}/${id}`).then((res) => {return res}).catch((err) => { throw err });
}
