import React, {useEffect, useState} from "react";
import "./special-leftmenu.scss";
import { connect } from "react-redux";
import {bindActionCreators} from "redux";
import * as appActions from "../../../core/app.store";

function SpecialLeftmenu(props) {
  return (
    <div className="left-menu-custom-container h-100 position-relative special-planning">
      <div className="special-left-area-title">
        Quy hoạch <br />Đơn vị hành chính kinh tế đặc biệt
      </div>
        <div className="special-list-box">
            <div className="special-item">
                <a href="/ban-do-quy-hoach/15" title="Khu kinh tế Vân Đồn">Khu kinh tế Vân Đồn</a>
            </div>
            <div className="special-item">
                <a href="/ban-do-quy-hoach/17" title="Khu kinh tế Vân Đồn">Khu kinh tế Bắc Vân Phong</a>
            </div>
            <div className="special-item">
                <a href="/ban-do-quy-hoach/16" title="Khu kinh tế Vân Đồn">Khu kinh tế Phú Quốc</a>
            </div>
            <div className="cb"></div>
        </div>
        <a title="Cơ sở dữ liệu liên quan" href="/quy-hoach/43">
            <div className="related-button">
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left"
                     className="svg-inline--fa fa-arrow-left fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="currentColor"
                          d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                </svg>
                &nbsp;
                <span>Cơ sở dữ liệu liên quan </span>
            </div>
        </a>
    </div>
  );
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(SpecialLeftmenu));
