import React, { Component } from 'react';
import ReportPlanningProject from './report-planning-project/report-planning-project.view'
import ReportSituation from './report-situation/report-situation.view'
function Chart(props) {
    return (
        <div className="container chart-view-wrapper pb-4">
            <ReportPlanningProject />
            <ReportSituation></ReportSituation>
        </div>
    );
}
 
export default Chart;