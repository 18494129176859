/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import * as reportAction from "./../../../redux/store/report/report.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "./../../../languages/vi-VN.json";
import { NotificationMessageType } from "../../../utils/configuration";
import "./report-situation.scss";
import UrlCollect from "../../../common/url-collect";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        borderDash: [3, 3],
      },
      barPercentage: 0.3,
    },
  },
};

function ReportSituation(props) {
  const [dataReport, setDataReport] = useState([]);

  useEffect(() => {
    getDataReport()
  }, []);
  const getDataReport = async() => {
    await reportAction
    .GetPlanningKindSumaryReport()
    .then((res) => {
      if (res && res.content && res.content.length > 0) {
        const tmpData = [];
        for (let index = 0; index < res.content.length; index++) {
          const elementPlanningKind = res.content[index].planningKind;
          const elementRecordsStatus = res.content[index].recordsStatus;
          const tmpPlanningKind = elementPlanningKind.planningKindId;
          const tmpName = elementPlanningKind.planningKindName;
          const tmp = [
            {
              name: "Số đã phê duyệt",
              color: "#1F497C",
              count: 0,
            },
            {
              name: "Đã nộp",
              color: "#428BCA",
              count: 0,
            },
            {
              name: "Chưa nộp",
              color: "#BF504E",
              count: 0,
            },
            {
              name: "Đã tiếp nhận",
              color: "#F79647",
              count: 0,
            },
            {
              name: "Đã cập nhật lên hệ thống",
              color: "#4AACC5",
              count: 0,
            },
          ];
          const tmp1 = [0, 0, 0, 0, 0];
          for (let index = 0; index < elementRecordsStatus.length; index++) {
            const recordStatus = elementRecordsStatus[index];
            if (recordStatus.recordsStatusName === "Số đã phê duyệt") {
              tmp[0].count = recordStatus.count;
              tmp1[0] = recordStatus.count;
            }
            if (recordStatus.recordsStatusName === "Đã nộp") {
              tmp[1].count = recordStatus.count;
              tmp1[1] = recordStatus.count;
            }
            if (recordStatus.recordsStatusName === "Chưa nộp") {
              tmp[2].count = recordStatus.count;
              tmp1[2] = recordStatus.count;
            }
            if (recordStatus.recordsStatusName === "Đã tiếp nhận") {
              tmp[3].count = recordStatus.count;
              tmp1[3] = recordStatus.count;
            }
            if (
              recordStatus.recordsStatusName === "Đã cập nhật lên hệ thống"
            ) {
              tmp[4].count = recordStatus.count;
              tmp1[4] = recordStatus.count;
            }
          }
          const tmpObject = {
            name: tmpName,
            planningKindId: tmpPlanningKind,
            data: tmp,
            count: tmp1,
          };
          tmpData.push(tmpObject);
        }
        setDataReport(tmpData);
      }
    })
    .catch((err) => {
      err &&
        err.errorType &&
        ShowNotification(
          viVN.Errors[err.errorType],
          NotificationMessageType.Error
        );
    });
  }
  const labels = [
    "Số đã phê duyệt",
    "Đã nộp",
    "Chưa nộp",
    "Đã tiếp nhận",
    "Đã cập nhật lên hệ thống",
  ];

  return (
    <div className="report-situation-wrapper">
      {dataReport.length > 0 ? (
        <React.Fragment>
          <div className="wrapper-title">
            2. Tình hình nộp và xử lý hồ sơ quy hoạch đã phê duyệt
          </div>
          {dataReport.map((item, index) => (
            <div className="content content-01">
              <div className="d-flex justify-content-between align-items-center">
                <div className="title">{String.fromCharCode(index + 'A'.charCodeAt(0))}. {item.name}</div>
                <a
                  className="link"
                  href={`${
                    item?.planningKindId === 1
                      ? UrlCollect.PlanningProvince
                      : UrlCollect.PlanningRegionNation + `?planningKindId=${item?.planningKindId}`
                  }`}
                >
                  Xem chi tiết
                </a>
              </div>
              <div className="row mt-2">
                <div className="col-md-8">
                  {" "}
                  <Bar
                    options={options}
                    data={{
                      labels: labels,
                      datasets: [
                        {
                          data: item.count,
                          backgroundColor: [
                            "#1F497C",
                            "#428BCA",
                            "#BF504E",
                            "#F79647",
                            "#4AACC5",
                          ],
                          borderWidth: 0,
                          barThickness: 40,
                        },
                      ],
                    }}
                  />
                </div>
                <div className="col-md-4 content-right">
                  {item.data.map((item, index) => (
                    <div
                      className="d-flex justify-content-between item"
                      key={index}
                    >
                      <div className="d-flex label">
                        <div
                          className="color"
                          style={{ backgroundColor: `${item.color}` }}
                        ></div>
                        <div className="text">{item.name}:</div>
                      </div>
                      <div className="value">{item.count}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
}

export default ReportSituation;
